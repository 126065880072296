.nondialog-only,
.nonmodal-only {
  display: inherit;
}

.dialog-only,
.modal-only {
  display: none;
}

.p-dialog {
  &.modal-sm > .p-dialog-content {
    width: $modal-sm;
    max-width: min($modal-sm, 100vw);
  }

  &.modal-md > .p-dialog-content {
    width: $modal-md;
    max-width: min($modal-md, 100vw);
  }

  &.modal-lg > .p-dialog-content {
    width: $modal-lg;
    max-width: min($modal-lg, 100vw);
  }

  &.modal-xl > .p-dialog-content {
    width: $modal-xl;
    max-width: min($modal-xl, 100vw);
  }

  > .p-dialog-content {
    .nondialog-only,
    .nonmodal-only {
      display: none;
    }

    .dialog-only,
    .modal-only {
      display: inherit;
    }

    border-radius: var(--bs-border-radius);

    padding: 0;
    overflow-x: hidden;
    overflow-y: hidden;

    width: $modal-md;
    max-width: min($modal-md, 100vw);
    max-height: 90vh;

    // Grow the modal contents to fit,
    // so we can anchor the footer to the bottom
    display: flex;
    flex-direction: column;

    & > * {
      // padding: 0 1.5rem 1.5rem 1.5rem;

      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: hidden;

      & > * {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
        overflow: hidden;

        & > * {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          flex-shrink: 1;
          overflow: hidden;

          & > * {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-shrink: 1;
            overflow: hidden;
          }
        }
      }
    }

    // Since we want responsive modals, @container queries would best
    // However, they don't play nicely will bootstrap's grid system
    // and @media queries are getting applied instead,
    // so in the nearer term, stack grid columns when displayed in a modal
    @each $breakpoint in map-keys($grid-breakpoints) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      @for $i from 1 through $grid-columns {
        @if str-length($infix) > 0 {
          .col#{$infix}-#{$i} {
            width: 100%;
          }
        }
      }
    }
  }
}

.list-lower-alpha {
  list-style: lower-alpha;
}
