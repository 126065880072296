// theming overrides
[data-bs-theme="dark"] {
  --bs-code-color: var(--bs-body-color);

  .text-secondary,
  .link-secondary,
  .btn-outline-secondary {
    color: var(--bs-secondary-color) !important;
  }
  .btn-outline-secondary {
    border-color: var(--bs-secondary-color) !important;
  }
  .btn-outline-secondary:hover {
    border-color: rgba(var(--bs-secondary-color-rgb), 0.6) !important;
    color: var(--bs-emphasis-color) !important;
  }
  .link-secondary:hover {
    color: rgba(var(--bs-secondary-color-rgb), 0.6) !important;
  }
}
