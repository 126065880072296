.p-overlaypanel .p-overlaypanel-content {
  padding: 0;
  max-height: 70vh;
  overflow: auto;

  > standard-page-section > standard-card > div {
    border-width: 0 !important;
  }
}

.p-tabview .p-tabview-panels {
  padding: 0;
}

:disabled {
  .p-checkbox,
  .p-radiobutton,
  .p-inputswitch-checked,
  .p-multiselect,
  .p-editor-container {
    cursor: default !important;
    pointer-events: none;

    @extend .p-disabled;
  }

  .p-hidden-accessible,
  .p-multiselect-token-icon,
  .p-multiselect-clear-icon,
  .p-password .p-password-clear-icon,
  .p-password i.pi,
  .p-chip .pi-chip-remove-icon {
    display: none;
  }
}

// The base prime.min.scss positions this wrong for some reason, hopefully can remove in a future release
.p-password .p-password-clear-icon {
  margin-top: 0;
}

// Ensure certain tooltips are wider than others,
// which nice wrapping, but not too wide and not too narrow
.p-tooltip.p-tooltip-lg > .p-tooltip-text {
  width: min(260px, calc(100vw - 2rem)) !important;
}

// For tables inside of cards, we want this to be rounded
.rounded-top .p-datatable-thead > tr {
  > th:first-child {
    border-top-left-radius: var(--bs-border-radius);
  }

  > th:last-child {
    border-top-right-radius: var(--bs-border-radius);
  }
}

// Make badges tighter
.p-badge {
  border-radius: 50px;
  padding: 0 0.25rem;
  border-left: 1px solid white;
  border-bottom: 1px solid white;
  font-size: 0.75rem;

  font-weight: normal;
  min-width: 0;
  height: 1rem;
  line-height: 1rem;
}

.p-overlay-badge .p-badge {
  transform: translate(25%, -25%) !important;
}

/*
#86161
When item label is long enough to introduce a horizontal scroll bar,
if there is only 1 item in the list then the scroll bar will cover part of the text,
which in turn triggers a vertical scroll bar.

Setting the min-height to 56px here forces the dropdown overlay to always be at least tall enough to support a single item
and a horizontal scroll bar without scrolling vertically.
*/
.p-multiselect-items-wrapper .p-scroller-viewport .p-scroller {
  min-height: 56px;
}
