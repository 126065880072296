@use "primeng/resources/primeng.min.css";

* {
  box-sizing: border-box;
}

.p-component-overlay {
  --bs-bg-opacity: 0.2;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.6;
}

.p-error {
  color: var(--bs-danger);
}

.p-text-secondary {
  color: var(--bs-body-color);
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 1rem;
  border-radius: var(--bs-border-radius);
}
.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-focus-box-shadow);
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}

// .p-autocomplete .p-autocomplete-loader {
//   right: 0.75rem;
// }
// .p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
//   right: 3.75rem;
// }
// .p-autocomplete .p-autocomplete-multiple-container {
//   padding: 0.375rem 0.75rem;
// }
// .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
//   border-color: var(--bs-border-color);
// }
// .p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-form-select-focus-box-shadow);
//   border-color: var(--bs-emphasis-color);
// }
// .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
//   padding: 0.375rem 0;
// }
// .p-autocomplete
//   .p-autocomplete-multiple-container
//   .p-autocomplete-input-token
//   input {
//   font-size: 1rem;
//   color: var(--bs-body-color);
//   padding: 0;
//   margin: 0;
// }
// .p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
//   padding: 0.375rem 0.75rem;
//   margin-right: 0.5rem;
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
//   border-radius: var(--bs-border-radius);
// }
// .p-autocomplete
//   .p-autocomplete-multiple-container
//   .p-autocomplete-token
//   .p-autocomplete-token-icon {
//   margin-left: 0.5rem;
// }

// p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
//   border-color: var(--bs-danger-bg-subtle);
// }

// .p-autocomplete-panel {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   border-radius: var(--bs-border-radius);
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {
//   padding: 0.75rem 0;
// }
// .p-autocomplete-panel
//   .p-autocomplete-items.p-autocomplete-virtualscroll
//   .cdk-virtual-scroll-content-wrapper {
//   padding: 0.75rem 0;
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
//   margin: 0;
//   padding: 0.75rem 1.25rem;
//   border: 0 none;
//   color: var(--bs-body-color);
//   background: transparent;
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
//   color: var(--bs-emphasis-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   background: transparent;
// }
// .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
//   margin: 0;
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   background: var(--bs-body-bg);
//   font-weight: 700;
// }

// p-autocomplete.p-autocomplete-clearable .p-inputtext {
//   padding-right: 2.5rem;
// }
// p-autocomplete.p-autocomplete-clearable .p-autocomplete-clear-icon {
//   color: var(--bs-body-color);
//   right: 0.75rem;
// }

// p-autocomplete.p-autocomplete-clearable.p-autocomplete-dd
//   .p-autocomplete-clear-icon {
//   color: var(--bs-body-color);
//   right: 3.75rem;
// }

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: var(--bs-danger-bg-subtle);
}

.p-calendar .p-datepicker {
  min-width: auto !important;
}

.p-datepicker {
  padding: 0.25rem;
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 1px solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}
.p-datepicker:not(.p-datepicker-inline) {
  background: var(--bs-body-bg);
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: var(--bs-body-bg);
}
.p-datepicker .p-datepicker-header {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  color: var(--bs-body-color);
  background: var(--bs-body-bg);
  font-weight: 600;
  margin: 0;
  border: 1px solid var(--bs-border-color);
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 1.5rem;
  color: var(--bs-body-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: var(--bs-transition-base);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: var(--bs-body-color);
  border-color: transparent;
  background: var(--bs-tertiary-bg);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-btn-focus-box-shadow);
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 1.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: var(--bs-body-color);
  transition: var(--bs-transition-base);
  font-weight: 600;
}
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: var(--bs-secondary-color);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 1rem;
  margin: 0.25rem 0;
}
.p-datepicker table th {
  padding: 0.25rem;
  text-align: center;
}
.p-datepicker table th > span {
  width: 2rem;
  height: 2rem;
}
.p-datepicker table td {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.p-datepicker table td > span {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  transition: var(--bs-transition-base);
  border: 1px solid transparent;
}
.p-datepicker table td > span.p-highlight {
  color: var(--bs-body-bg);
  background: var(--bs-secondary-color);
}
.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-btn-focus-box-shadow);
}
// .p-datepicker table td.p-datepicker-today > span {
//   color: var(--bs-body-color);
//   background: var(--bs-secondary-bg);
//   border-color: transparent;
// }
// .p-datepicker table td.p-datepicker-today > span.p-highlight {
//   color: var(--bs-body-bg);
//   background: var(--bs-secondary-color);
// }
.p-datepicker .p-datepicker-buttonbar {
  padding: 0.5rem 0;
  border-top: 1px solid var(--bs-border-color);
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  padding-top: 0.25rem;
  border-top: 1px solid var(--bs-border-color);
}
.p-datepicker .p-timepicker button {
  color: var(--bs-body-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: var(--bs-transition-base);
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: var(--bs-body-color);
  border-color: transparent;
  background: var(--bs-tertiary-bg);
}
.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-form-select-focus-box-shadow);
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.25rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.25rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.25rem;
  transition: var(--bs-transition-base);
  border-radius: var(--bs-border-radius);
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--bs-emphasis-color);
  background: var(--bs-tertiary-bg);
}
.p-datepicker .p-yearpicker {
  margin: 0.25rem 0;
}
.p-datepicker .p-yearpicker .p-yearpicker-year {
  padding: 0.25rem;
  transition: var(--bs-transition-base);
  border-radius: var(--bs-border-radius);
}
.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  color: var(--bs-emphasis-color);
  background: var(--bs-tertiary-bg);
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-left: 1px solid var(--bs-border-color);
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
  border-left: 0 none;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):hover {
  background: var(--bs-tertiary-bg);
}
.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-form-select-focus-box-shadow);
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover {
  background: var(--bs-tertiary-bg);
}
.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-focus-box-shadow);
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
  background: var(--bs-tertiary-bg);
}
.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-focus-box-shadow);
}

p-calendar.p-calendar-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-calendar.p-calendar-clearable .p-calendar-clear-icon {
  color: var(--bs-body-color);
  right: 0.75rem;
}

p-calendar.p-calendar-clearable.p-calendar-w-btn .p-calendar-clear-icon {
  color: var(--bs-body-color);
  right: 3.75rem;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
// .p-cascadeselect {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color);
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
// }
// .p-cascadeselect:not(.p-disabled):hover {
//   border-color: var(--bs-emphasis-color);
// }
// .p-cascadeselect:not(.p-disabled).p-focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-form-select-focus-box-shadow);
//   border-color: var(--bs-emphasis-color);
// }
// .p-cascadeselect .p-cascadeselect-label {
//   background: transparent;
//   border: 0 none;
//   padding: 0.75rem 0.75rem;
// }
// .p-cascadeselect .p-cascadeselect-label.p-placeholder {
//   color: var(--bs-tertiary-color);
// }
// .p-cascadeselect .p-cascadeselect-label:enabled:focus {
//   outline: 0 none;
//   box-shadow: none;
// }
// .p-cascadeselect .p-cascadeselect-trigger {
//   background: transparent;
//   color: var(--bs-body-color);
//   width: 3rem;
//   border-top-right-radius: var(--bs-border-radius);
//   border-bottom-right-radius: var(--bs-border-radius);
// }

// .p-cascadeselect-panel {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   border-radius: var(--bs-border-radius);
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-cascadeselect-panel .p-cascadeselect-items {
//   padding: 0.75rem 0;
// }
// .p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
//   margin: 0;
//   border: 0 none;
//   color: var(--bs-body-color);
//   background: transparent;
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
// }
// .p-cascadeselect-panel
//   .p-cascadeselect-items
//   .p-cascadeselect-item
//   .p-cascadeselect-item-content {
//   padding: 0.75rem 1.25rem;
// }
// .p-cascadeselect-panel
//   .p-cascadeselect-items
//   .p-cascadeselect-item
//   .p-cascadeselect-item-content:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-cascadeselect-panel
//   .p-cascadeselect-items
//   .p-cascadeselect-item.p-highlight {
//   color: var(--bs-emphasis-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-cascadeselect-panel
//   .p-cascadeselect-items
//   .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-cascadeselect-panel
//   .p-cascadeselect-items
//   .p-cascadeselect-item
//   .p-cascadeselect-group-icon {
//   font-size: 0.875rem;
// }

// p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
//   border-color: var(--bs-danger-bg-subtle);
// }

// .p-input-filled .p-cascadeselect {
//   background: var(--bs-tertiary-bg);
// }
// .p-input-filled .p-cascadeselect:not(.p-disabled):hover {
//   background-color: $gray-200;
// }
// .p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
//   background-color: color-contrast($secondary);
// }

// p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-label {
//   padding-right: 0.75rem;
// }
// p-cascadeselect.p-cascadeselect-clearable .p-cascadeselect-clear-icon {
//   color: var(--bs-body-color);
//   right: 3rem;
// }

.p-checkbox {
  width: 1.3rem;
  height: 1.3rem;
}
.p-checkbox .p-checkbox-box {
  border: 1px solid var(--bs-tertiary-color);
  background: var(--bs-body-bg);
  width: 1.3rem;
  height: 1.3rem;
  color: var(--bs-body-color);
  border-radius: var(--bs-border-radius);
  transition: var(--bs-transition-base);
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: var(--bs-body-bg);
  font-size: 0.875rem;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--bs-form-checkbox-fill-color);
  background: var(--bs-form-checkbox-fill-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background: rgba(var(--bs-emphasis-color-rgb), 0.1);
  border-color: var(--bs-body-color);
  color: var(--bs-body-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--bs-form-checkbox-fill-color-hover);
  border-color: var(--bs-form-checkbox-fill-color-hover);
  color: var(--bs-form-checkbox-fill-color-hover);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-form-check-input-focus-box-shadow);
  border-color: var(--bs-emphasis-color);
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: var(--bs-danger-bg-subtle);
}

// .p-input-filled .p-checkbox .p-checkbox-box {
//   background-color: $gray-200;
// }
// .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
//   background: var(--bs-secondary-color);
// }
// .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
//   background-color: $gray-200;
// }
// .p-input-filled
//   .p-checkbox:not(.p-checkbox-disabled)
//   .p-checkbox-box.p-highlight:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
// }

.p-checkbox-label {
  margin-left: 0.5rem;
}

// .p-chips .p-chips-multiple-container {
//   padding: 0.375rem 0.75rem;
// }
// .p-chips .p-chips-multiple-container:not(.p-disabled):hover {
//   border-color: var(--bs-emphasis-color);
// }
// .p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
//   border-color: var(--bs-emphasis-color);
// }
// .p-chips .p-chips-multiple-container .p-chips-token {
//   padding: 0.375rem 0.75rem;
//   margin-right: 0.5rem;
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
//   border-radius: var(--bs-border-radius);
// }
// .p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
//   margin-left: 0.5rem;
// }
// .p-chips .p-chips-multiple-container .p-chips-input-token {
//   padding: 0.375rem 0;
// }
// .p-chips .p-chips-multiple-container .p-chips-input-token input {
//   font-size: 1rem;
//   color: var(--bs-body-color);
//   padding: 0;
//   margin: 0;
// }

// p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
//   border-color: var(--bs-danger-bg-subtle);
// }

// p-chips.p-chips-clearable .p-inputtext {
//   padding-right: 1.75rem;
// }
// p-chips.p-chips-clearable .p-chips-clear-icon {
//   color: var(--bs-body-color);
//   right: 0.75rem;
// }

// .p-colorpicker-preview,
// .p-fluid .p-colorpicker-preview.p-inputtext {
//   width: 2rem;
//   height: 50px;
// }

// .p-colorpicker-panel {
//   background: #323232;
//   border: 1px solid #191919;
// }
// .p-colorpicker-panel .p-colorpicker-color-handle,
// .p-colorpicker-panel .p-colorpicker-hue-handle {
//   border-color: color-contrast($secondary);
// }

// .p-colorpicker-overlay-panel {
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }

.p-dropdown {
  background: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color);
  transition: var(--bs-transition-base);
  border-radius: var(--bs-border-radius);
}
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--bs-emphasis-color);
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
  border-color: var(--bs-emphasis-color);
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}
.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--bs-tertiary-color);
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: var(--bs-body-color);
  width: 3rem;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}
.p-dropdown .p-dropdown-clear-icon {
  color: var(--bs-body-color);
  right: 3rem;
}

.p-dropdown-panel {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 0 none;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem 1.25rem;
  border: 1px solid var(--bs-border-color);
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
  margin: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
  margin-right: -1.75rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: var(--bs-body-color);
}
.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
  padding: 0.75rem 0;
}
.p-dropdown-panel
  .p-dropdown-items.p-dropdown-virtualscroll
  .cdk-virtual-scroll-content-wrapper {
  padding: 0.75rem 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: var(--bs-body-color);
  background: transparent;
  transition: var(--bs-transition-base);
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--bs-emphasis-color);
  background: var(--bs-tertiary-bg);
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.75rem 1.25rem;
  color: var(--bs-body-color);
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: var(--bs-body-color);
  background: var(--bs-body-bg);
  font-weight: 700;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: var(--bs-danger-bg-subtle);
}

// .p-input-filled .p-dropdown {
//   background: var(--bs-tertiary-bg);
// }
// .p-input-filled .p-dropdown:not(.p-disabled):hover {
//   background-color: $gray-200;
// }
// .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
//   background-color: $white;
// }
// .p-input-filled .p-dropdown:not(.p-disabled).p-focus .p-inputtext {
//   background-color: transparent;
// }

.p-editor-container .p-editor-toolbar {
  background: var(--bs-tertiary-bg);
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid var(--bs-border-color-translucent);
}
.p-editor-container:focus-within .p-editor-toolbar.ql-snow {
  border-color: var(--bs-emphasis-color);
}
.p-editor-container:hover .p-editor-toolbar.ql-snow {
  border-color: var(--bs-emphasis-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: var(--bs-secondary-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: var(--bs-secondary-color);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover {
  color: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-stroke {
  stroke: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker
  .ql-picker-label:hover
  .ql-fill {
  fill: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label {
  color: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-stroke {
  stroke: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-label
  .ql-fill {
  fill: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options {
  background: var(--bs-body-bg);
  border: 0 none;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--bs-border-radius);
  padding: 0.75rem 0;
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item {
  color: var(--bs-body-color);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded
  .ql-picker-options
  .ql-picker-item:hover {
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
}
.p-editor-container
  .p-editor-toolbar.ql-snow
  .ql-picker.ql-expanded:not(.ql-icon-picker)
  .ql-picker-item {
  padding: 0.75rem 1.25rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid var(--bs-border-color-translucent);
}
.p-editor-container:focus-within .p-editor-content.ql-snow {
  box-shadow: var(--bs-input-focus-box-shadow);
  border-color: var(--bs-emphasis-color);
}
.p-editor-container:hover .p-editor-content.ql-snow {
  border-color: var(--bs-emphasis-color);
}
.p-editor-container .p-editor-content .ql-editor {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: var(--bs-body-color);
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: var(--bs-body-color);
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: var(--bs-body-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: var(--bs-secondary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: var(--bs-secondary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--bs-secondary-color);
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-label.ql-active
  .ql-picker-label,
.p-editor-container
  .ql-snow.ql-toolbar
  .ql-picker-item.ql-selected
  .ql-picker-label {
  color: var(--bs-secondary-color);
}

// .p-inputgroup-addon {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border-top: 1px solid var(--bs-border-color);
//   border-left: 1px solid var(--bs-border-color);
//   border-bottom: 1px solid var(--bs-border-color);
//   padding: 0.75rem 0.75rem;
//   min-width: 3rem;
// }
// .p-inputgroup-addon:last-child {
//   border-right: 1px solid var(--bs-border-color);
// }

// .p-inputgroup > .p-component,
// .p-inputgroup > .p-element,
// .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
// .p-inputgroup > .p-float-label > .p-component {
//   border-radius: 0;
//   margin: 0;
// }
// .p-inputgroup > .p-component + .p-inputgroup-addon,
// .p-inputgroup > .p-element + .p-inputgroup-addon,
// .p-inputgroup
//   > .p-inputwrapper
//   > .p-component
//   > .p-inputtext
//   + .p-inputgroup-addon,
// .p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
//   border-left: 0 none;
// }
// .p-inputgroup > .p-component:focus,
// .p-inputgroup > .p-element:focus,
// .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
// .p-inputgroup > .p-float-label > .p-component:focus {
//   z-index: 1;
// }
// .p-inputgroup > .p-component:focus ~ label,
// .p-inputgroup > .p-element:focus ~ label,
// .p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
// .p-inputgroup > .p-float-label > .p-component:focus ~ label {
//   z-index: 1;
// }

// .p-inputgroup-addon:first-child,
// .p-inputgroup button:first-child,
// .p-inputgroup input:first-child,
// .p-inputgroup > .p-inputwrapper:first-child > .p-component,
// .p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
//   border-top-left-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }

// .p-inputgroup .p-float-label:first-child input {
//   border-top-left-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }

// .p-inputgroup-addon:last-child,
// .p-inputgroup button:last-child,
// .p-inputgroup input:last-child,
// .p-inputgroup > .p-inputwrapper:last-child > .p-component,
// .p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
//   border-top-right-radius: var(--bs-border-radius);
//   border-bottom-right-radius: var(--bs-border-radius);
// }

// .p-inputgroup .p-float-label:last-child input {
//   border-top-right-radius: var(--bs-border-radius);
//   border-bottom-right-radius: var(--bs-border-radius);
// }

// .p-fluid .p-inputgroup .p-button {
//   width: auto;
// }
// .p-fluid .p-inputgroup .p-button.p-button-icon-only {
//   width: 3rem;
// }

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: var(--bs-danger-bg-subtle);
}

p-inputmask.p-inputmask-clearable .p-inputtext {
  padding-right: 2.5rem;
}
p-inputmask.p-inputmask-clearable .p-inputmask-clear-icon {
  color: var(--bs-body-color);
  right: 0.75rem;
}

p-inputnumber .p-inputnumber-input {
  max-width: 100%;
}
p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: var(--bs-danger-bg-subtle);
}

p-inputnumber.p-inputnumber-clearable .p-inputnumber-input {
  padding-right: 2.5rem;
}
p-inputnumber.p-inputnumber-clearable .p-inputnumber-clear-icon {
  color: var(--bs-body-color);
  right: 0.75rem;
}

p-inputnumber.p-inputnumber-clearable
  .p-inputnumber-buttons-stacked
  .p-inputnumber-clear-icon {
  right: 3.75rem;
}
p-inputnumber.p-inputnumber-clearable
  .p-inputnumber-buttons-horizontal
  .p-inputnumber-clear-icon {
  right: 3.75rem;
}

.p-inputswitch {
  width: 3rem;
  height: 1.5rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: rgba(var(--bs-emphasis-color-rgb), 0.2);
  transition: var(--bs-transition-base);
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: var(--bs-body-bg);
  width: 1.25rem;
  height: 1.25rem;
  left: 0.2rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.4rem);
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-focus-box-shadow);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: rgba(var(--bs-emphasis-color-rgb), 0.2);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--bs-form-toggle-fill-color);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background-color: var(--bs-btn-color);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider {
  background-color: var(--bs-form-toggle-fill-color-hover);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover
  .p-inputswitch-slider:before {
  background-color: var(--bs-btn-hover-color);
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: var(--bs-danger-bg-subtle);
}

.p-inputtext {
  font-size: var(--bs-input-font-size);
  min-height: var(--bs-h-form-control);
  color: var(--bs-body-color);
  background: var(--bs-body-bg);
  padding: 8px 12px;
  border: 1px solid var(--bs-tertiary-color);
  transition: var(--bs-transition-base);
  appearance: none;
  border-radius: var(--bs-border-radius);
}
.p-inputtext:enabled:hover {
  border-color: var(--bs-emphasis-color);
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-focus-box-shadow);
  border-color: var(--bs-emphasis-color);
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: var(--bs-danger-bg-subtle);
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

.p-float-label > label {
  left: 0.75rem;
  color: var(--bs-body-color);
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: var(--bs-danger);
}

.p-input-icon-left > .p-icon-wrapper.p-icon,
.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
  color: var(--bs-body-color);
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-input-icon-right > .p-icon-wrapper,
.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: var(--bs-body-color);
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: var(--bs-tertiary-color);
}

// .p-input-filled .p-inputtext {
//   background-color: $gray-200;
// }
// .p-input-filled .p-inputtext:enabled:hover {
//   background-color: $gray-200;
// }
// .p-input-filled .p-inputtext:enabled:focus {
//   background-color: $white;
// }

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.65625rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.9375rem 0.9375rem;
}

// .p-listbox {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color);
//   border-radius: var(--bs-border-radius);
// }
// .p-listbox .p-listbox-header {
//   padding: 0.75rem 1.25rem;
//   border: 1px solid var(--bs-border-color);
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
//   margin: 0;
//   border-top-right-radius: var(--bs-border-radius);
//   border-top-left-radius: var(--bs-border-radius);
// }
// .p-listbox .p-listbox-header .p-listbox-filter {
//   padding-right: 1.75rem;
// }
// .p-listbox .p-listbox-header .p-listbox-filter-icon {
//   right: 0.75rem;
//   color: var(--bs-body-color);
// }
// .p-listbox .p-listbox-header .p-checkbox {
//   margin-right: 0.5rem;
// }
// .p-listbox .p-listbox-list {
//   padding: 0.75rem 0;
// }
// .p-listbox .p-listbox-list .p-listbox-item {
//   margin: 0;
//   padding: 0.75rem 1.25rem;
//   border: 0 none;
//   color: var(--bs-body-color);
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
// }
// .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
//   color: var(--bs-emphasis-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-listbox .p-listbox-list .p-listbox-item:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
//   margin-right: 0.5rem;
// }
// .p-listbox .p-listbox-list .p-listbox-item-group {
//   margin: 0;
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   background: var(--bs-body-bg);
//   font-weight: 700;
// }
// .p-listbox .p-listbox-list .p-listbox-empty-message {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   background: transparent;
// }
// .p-listbox:not(.p-disabled)
//   .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
// }

// p-listbox.ng-dirty.ng-invalid > .p-listbox {
//   border-color: var(--bs-danger-bg-subtle);
// }

.p-multiselect {
  background: var(--bs-body-bg);
  border: 1px solid var(--bs-tertiary-color);
  transition: var(--bs-transition-base);
  border-radius: var(--bs-border-radius);
  min-height: var(--bs-h-form-control);
}
.p-multiselect:not(.p-disabled):hover {
  border-color: var(--bs-emphasis-color);
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-form-select-focus-box-shadow);
  border-color: var(--bs-emphasis-color);
}
.p-multiselect .p-multiselect-label {
  padding: 8px 12px;
  transition: var(--bs-transition-base);
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--bs-tertiary-color);
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.275rem 0.5rem;
  margin-right: 0.5rem;
  background: var(--bs-tertiary-bg);
  color: var(--bs-emphasis-color);
  border-radius: var(--bs-border-radius);
}
.p-multiselect.p-multiselect-chip
  .p-multiselect-token
  .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: var(--bs-body-color);
  width: 3rem;
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.3rem 0.75rem;
}

.p-multiselect-panel {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 0 none;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.25rem 1.25rem;
  border: 1px solid var(--bs-border-color);
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
  margin: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-inputtext {
  padding-right: 1.75rem;
}
.p-multiselect-panel
  .p-multiselect-header
  .p-multiselect-filter-container
  .p-multiselect-filter-icon {
  right: 0.75rem;
  color: var(--bs-body-color);
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: var(--bs-body-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: var(--bs-transition-base);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: var(--bs-body-color);
  border-color: transparent;
  background: var(--bs-tertiary-bg);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-focus-box-shadow);
}
.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0.25rem 0;
}
.p-multiselect-panel
  .p-multiselect-items.p-multiselect-virtualscroll
  .cdk-virtual-scroll-content-wrapper {
  padding: 0.75rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: var(--bs-body-color);
  background: transparent;
  transition: var(--bs-transition-base);
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: var(--bs-emphasis-color);
  background: var(--bs-tertiary-bg);
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-input-focus-box-shadow);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1.25rem;
  color: var(--bs-body-color);
  background: var(--bs-body-bg);
  font-weight: 700;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1.25rem;
  color: var(--bs-body-color);
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: var(--bs-danger-bg-subtle);
}

// .p-input-filled .p-multiselect {
//   background: var(--bs-tertiary-bg);
// }
// .p-input-filled .p-multiselect:not(.p-disabled):hover {
//   background-color: $gray-200;
// }
// .p-input-filled .p-multiselect:not(.p-disabled).p-focus {
//   background-color: color-contrast($secondary);
// }

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 1.75rem;
  display: flex;
}
p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: var(--bs-body-color);
  right: 3rem;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: var(--bs-danger-bg-subtle);
}

.p-password-panel {
  padding: 1.25rem;
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: var(--bs-border-radius);
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: var(--bs-tertiary-bg);
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: var(--bs-danger);
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: var(--bs-warning);
}

.p-password-panel .p-password-meter .p-password-strength.strong {
  background: var(--bs-success);
}

p-password.p-password-clearable .p-password-input {
  padding-right: 2.5rem;
}

p-password.p-password-clearable .p-password-clear-icon {
  color: var(--bs-body-color);
  right: 0.75rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
  padding-right: 4.25rem;
}

p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
  color: var(--bs-body-color);
  right: 2.5rem;
}

.p-radiobutton {
  width: 22px;
  height: 22px;
}
.p-radiobutton .p-radiobutton-box {
  border: 1px solid var(--bs-tertiary-color);
  background: var(--bs-body-bg);
  width: 22px;
  height: 22px;
  color: var(--bs-body-color);
  border-radius: 50%;
  transition: var(--bs-transition-base);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(var(--bs-emphasis-color-rgb), 0.1);
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-form-check-input-focus-box-shadow);
  border-color: var(--bs-emphasis-color);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: var(--bs-form-radio-circle-fill-color);
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-width: 1px;
  border-color: var(--bs-secondary-color);
  background: var(--bs-tertiary-bg);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--bs-emphasis-color);
  color: var(--bs-emphasis-color);
}

.p-radiobutton
  .p-radiobutton-box.p-highlight:not(.p-disabled):hover
  .p-radiobutton-icon {
  background-color: var(--bs-form-radio-circle-fill-color-hover);
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: var(--bs-danger-bg-subtle);
}

// .p-input-filled .p-radiobutton .p-radiobutton-box {
//   background-color: $gray-200;
// }
// .p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
//   background-color: $gray-200;
// }
// .p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
//   background: var(--bs-secondary-color);
// }
// .p-input-filled
//   .p-radiobutton
//   .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
// }

.p-radiobutton-label {
  margin-left: 0.5rem;
}

// .p-rating .p-rating-icon {
//   color: var(--bs-body-color);
//   margin-left: 0.5rem;
//   transition: var(--bs-transition-base);
//   font-size: 1.143rem;
// }
// .p-rating .p-rating-icon.p-rating-cancel {
//   color: var(--bs-danger);
// }
// .p-rating .p-rating-icon:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-form-check-input-focus-box-shadow);
// }
// .p-rating .p-rating-icon:first-child {
//   margin-left: 0;
// }
// .p-rating .p-rating-icon.pi-star-fill {
//   color: var(--bs-secondary-color);
// }
// .p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
//   color: var(--bs-secondary-color);
// }
// .p-rating:not(.p-disabled):not(.p-readonly)
//   .p-rating-icon.p-rating-cancel:hover {
//   color: var(--bs-danger);
// }

// .p-selectbutton .p-button {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color);
//   color: var(--bs-body-color);
//   transition: var(--bs-transition-base);
// }
// .p-selectbutton .p-button .p-button-icon-left,
// .p-selectbutton .p-button .p-button-icon-right {
//   color: var(--bs-body-color);
// }
// .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   border-color: $gray-400;
//   color: var(--bs-body-color);
// }
// .p-selectbutton
//   .p-button:not(.p-disabled):not(.p-highlight):hover
//   .p-button-icon-left,
// .p-selectbutton
//   .p-button:not(.p-disabled):not(.p-highlight):hover
//   .p-button-icon-right {
//   color: var(--bs-body-color);
// }
// .p-selectbutton .p-button.p-highlight {
//   background: var(--bs-secondary-color);
//   border-color: var(--bs-border-color);
//   color: color-contrast($secondary);
// }
// .p-selectbutton .p-button.p-highlight .p-button-icon-left,
// .p-selectbutton .p-button.p-highlight .p-button-icon-right {
//   color: color-contrast($secondary);
// }
// .p-selectbutton .p-button.p-highlight:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   border-color: var(--bs-emphasis-color);
//   color: color-contrast(shade-color($secondary, 20%));
// }
// .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
// .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
//   color: color-contrast($secondary);
// }

// p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
//   border-color: var(--bs-danger-bg-subtle);
// }

// .p-slider {
//   background: $gray-300;
//   border: 0 none;
//   border-radius: var(--bs-border-radius);
// }
// .p-slider.p-slider-horizontal {
//   height: 0.286rem;
// }
// .p-slider.p-slider-horizontal .p-slider-handle {
//   margin-top: -0.5715rem;
//   margin-left: -0.5715rem;
// }
// .p-slider.p-slider-vertical {
//   width: 0.286rem;
// }
// .p-slider.p-slider-vertical .p-slider-handle {
//   margin-left: -0.5715rem;
//   margin-bottom: -0.5715rem;
// }
// .p-slider .p-slider-handle {
//   height: 1.143rem;
//   width: 1.143rem;
//   background: var(--bs-body-bg);
//   border: 2px solid var(--bs-secondary-color);
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
// }
// .p-slider .p-slider-handle:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-slider .p-slider-range {
//   background: var(--bs-secondary-color);
// }
// .p-slider:not(.p-disabled) .p-slider-handle:hover {
//   background: var(--bs-secondary-color);
//   border-color: var(--bs-border-color);
// }
// .p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
//   transition: var(--bs-transition-base);
// }
// .p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
//   transition: var(--bs-transition-base);
// }
// .p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
//   transition: var(--bs-transition-base);
// }
// .p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
//   transition: var(--bs-transition-base);
// }

// .p-togglebutton.p-button {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color);
//   color: var(--bs-body-color);
//   transition: var(--bs-transition-base);
// }
// .p-togglebutton.p-button .p-button-icon-left,
// .p-togglebutton.p-button .p-button-icon-right {
//   color: var(--bs-body-color);
// }
// .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   border-color: $gray-400;
//   color: var(--bs-body-color);
// }
// .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
//   .p-button-icon-left,
// .p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover
//   .p-button-icon-right {
//   color: var(--bs-body-color);
// }
// .p-togglebutton.p-button.p-highlight {
//   background: var(--bs-secondary-color);
//   border-color: var(--bs-border-color);
//   color: color-contrast($secondary);
// }
// .p-togglebutton.p-button.p-highlight .p-button-icon-left,
// .p-togglebutton.p-button.p-highlight .p-button-icon-right {
//   color: color-contrast($secondary);
// }
// .p-togglebutton.p-button.p-highlight:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   border-color: var(--bs-emphasis-color);
//   color: color-contrast(shade-color($secondary, 20%));
// }
// .p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
// .p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
//   color: color-contrast($secondary);
// }

// p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
//   border-color: var(--bs-danger-bg-subtle);
// }

// .p-treeselect {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color);
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
// }
// .p-treeselect:not(.p-disabled):hover {
//   border-color: var(--bs-border-color);
// }
// .p-treeselect:not(.p-disabled).p-focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-form-select-focus-box-shadow);
//   border-color: var(--bs-emphasis-color);
// }
// .p-treeselect .p-treeselect-label {
//   padding: 0.75rem 0.75rem;
//   transition: var(--bs-transition-base);
// }
// .p-treeselect .p-treeselect-label.p-placeholder {
//   color: var(--bs-tertiary-color);
// }
// .p-treeselect.p-treeselect-chip .p-treeselect-token {
//   padding: 0.375rem 0.75rem;
//   margin-right: 0.5rem;
//   background: $gray-300;
//   color: var(--bs-body-color);
//   border-radius: 16px;
// }
// .p-treeselect .p-treeselect-trigger {
//   background: transparent;
//   color: var(--bs-body-color);
//   width: 3rem;
//   border-top-right-radius: var(--bs-border-radius);
//   border-bottom-right-radius: var(--bs-border-radius);
// }

// p-treeselect.ng-invalid.ng-dirty > .p-treeselect {
//   border-color: var(--bs-danger-bg-subtle);
// }

// .p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
//   padding: 0.375rem 0.75rem;
// }

// .p-treeselect-panel {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   border-radius: var(--bs-border-radius);
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-treeselect-panel .p-treeselect-header {
//   padding: 0.75rem 1.25rem;
//   border: 1px solid var(--bs-border-color);
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
//   margin: 0;
//   border-top-right-radius: var(--bs-border-radius);
//   border-top-left-radius: var(--bs-border-radius);
// }
// .p-treeselect-panel .p-treeselect-header .p-treeselect-filter-container {
//   margin-right: 0.5rem;
// }
// .p-treeselect-panel
//   .p-treeselect-header
//   .p-treeselect-filter-container
//   .p-treeselect-filter {
//   padding-right: 1.75rem;
// }
// .p-treeselect-panel
//   .p-treeselect-header
//   .p-treeselect-filter-container
//   .p-treeselect-filter-icon {
//   right: 0.75rem;
//   color: var(--bs-body-color);
// }
// .p-treeselect-panel
//   .p-treeselect-header
//   .p-treeselect-filter-container.p-treeselect-clearable-filter
//   .p-treeselect-filter {
//   padding-right: 3.5rem;
// }
// .p-treeselect-panel
//   .p-treeselect-header
//   .p-treeselect-filter-container.p-treeselect-clearable-filter
//   .p-treeselect-filter-clear-icon {
//   right: 2.5rem;
// }
// .p-treeselect-panel .p-treeselect-header .p-treeselect-close {
//   width: 2rem;
//   height: 2rem;
//   color: var(--bs-body-color);
//   border: 0 none;
//   background: transparent;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
// }
// .p-treeselect-panel .p-treeselect-header .p-treeselect-close:enabled:hover {
//   color: var(--bs-body-color);
//   border-color: transparent;
//   background: var(--bs-tertiary-bg);
// }
// .p-treeselect-panel .p-treeselect-header .p-treeselect-close:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
//   border: 0 none;
// }
// .p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   background: transparent;
// }

// .p-input-filled .p-treeselect {
//   background: var(--bs-tertiary-bg);
// }
// .p-input-filled .p-treeselect:not(.p-disabled):hover {
//   background-color: $gray-200;
// }
// .p-input-filled .p-treeselect:not(.p-disabled).p-focus {
//   background-color: color-contrast($secondary);
// }

// p-treeselect.p-treeselect-clearable .p-treeselect-label-container {
//   padding-right: 1.75rem;
// }
// p-treeselect.p-treeselect-clearable .p-treeselect-clear-icon {
//   color: var(--bs-body-color);
//   right: 3rem;
// }

.p-button {
  color: var(--bs-btn-color);
  background: var(--bs-btn-bg);
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  transition: var(--bs-transition-base);
  border-radius: var(--bs-border-radius);
}
.p-button:enabled:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
// .p-button.p-button-outlined {
//   background-color: transparent;
//   color: var(--bs-secondary-color);
//   border: 1px solid;
// }
// .p-button.p-button-outlined:enabled:hover {
//   color: var(--bs-btn-hover-color);
//   background-color: var(--bs-btn-hover-bg);
//   border-color: var(--bs-btn-hover-border-color);
// }
// .p-button.p-button-outlined.p-button-plain {
//   color: var(--bs-body-color);
//   border-color: var(--bs-secondary-bg);
// }
// .p-button.p-button-outlined.p-button-plain:enabled:hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-button.p-button-outlined.p-button-plain:enabled:active {
//   background: $gray-300;
//   color: var(--bs-body-color);
// }
.p-button.p-button-text {
  background-color: transparent;
  color: var(--bs-secondary-color);
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: tint-color($secondary, 90%);
  color: var(--bs-secondary-color);
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: tint-color($secondary, 90%);
  color: var(--bs-secondary-color);
  border-color: transparent;
}
// .p-button.p-button-text.p-button-plain {
//   color: var(--bs-body-color);
// }
// .p-button.p-button-text.p-button-plain:enabled:hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-button.p-button-text.p-button-plain:enabled:active {
//   background: $gray-300;
//   color: var(--bs-body-color);
// }
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
// .p-button .p-button-icon-left {
//   margin-right: 0.5rem;
// }
// .p-button .p-button-icon-right {
//   margin-left: 0.5rem;
// }
// .p-button .p-button-icon-bottom {
//   margin-top: 0.5rem;
// }
// .p-button .p-button-icon-top {
//   margin-bottom: 0.5rem;
// }
// .p-button .p-badge,
// .p-badge-icon .p-badge {
//   margin-left: 0.5rem;
//   min-width: 8px;
//   height: 8px;
//   line-height: 8px;
//   color: var(--bs-primary);
// }
// .p-button.p-button-raised {
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//     0 1px 5px 0 rgba(0, 0, 0, 0.12);
// }
// .p-button.p-button-rounded {
//   border-radius: 2rem;
// }
.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.75rem 0;
}
// .p-button.p-button-icon-only .p-button-icon-left,
// .p-button.p-button-icon-only .p-button-icon-right {
//   margin: 0;
// }
// .p-button.p-button-icon-only.p-button-rounded {
//   border-radius: 50%;
//   height: 3rem;
// }
// .p-button.p-button-sm {
//   font-size: 0.875rem;
//   padding: 0.65625rem 1.09375rem;
// }
// .p-button.p-button-sm .p-button-icon {
//   font-size: 0.875rem;
// }
// .p-button.p-button-lg {
//   font-size: 1.25rem;
//   padding: 0.9375rem 1.5625rem;
// }
// .p-button.p-button-lg .p-button-icon {
//   font-size: 1.25rem;
// }
// .p-button.p-button-loading-label-only .p-button-label {
//   margin-left: 0.5rem;
// }
// .p-button.p-button-loading-label-only .p-button-loading-icon {
//   margin-right: 0;
// }

// .p-fluid .p-button {
//   width: 100%;
// }
// .p-fluid .p-button-icon-only {
//   width: 3rem;
// }
// .p-fluid .p-buttonset {
//   display: flex;
// }
// .p-fluid .p-buttonset .p-button {
//   flex: 1;
// }

// .p-button.p-button-secondary,
// .p-buttonset.p-button-secondary > .p-button,
// .p-splitbutton.p-button-secondary > .p-button {
//   color: color-contrast($secondary);
//   background: var(--bs-secondary-color);
//   border: 1px solid var(--bs-secondary-color);
// }
// .p-button.p-button-secondary:enabled:hover,
// .p-buttonset.p-button-secondary > .p-button:enabled:hover,
// .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   color: color-contrast(shade-color($secondary, 20%));
//   border-color: var(--bs-emphasis-color);
// }
// .p-button.p-button-secondary:enabled:focus,
// .p-buttonset.p-button-secondary > .p-button:enabled:focus,
// .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
//   box-shadow: 0 0 0 0.2rem tint-color($secondary, 95%);
// }
// .p-button.p-button-secondary:enabled:active,
// .p-buttonset.p-button-secondary > .p-button:enabled:active,
// .p-splitbutton.p-button-secondary > .p-button:enabled:active {
//   background: tint-color($secondary, 30%);
//   color: color-contrast(tint-color($secondary, 30%));
//   border-color: tint-color($secondary, 30%);
// }
// .p-button.p-button-secondary.p-button-outlined,
// .p-buttonset.p-button-secondary > .p-button.p-button-outlined,
// .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
//   background-color: transparent;
//   color: var(--bs-secondary-color);
//   border: 1px solid;
// }
// .p-button.p-button-secondary.p-button-outlined:enabled:hover,
// .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
// .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-secondary-color);
//   border: 1px solid;
// }
// .p-button.p-button-secondary.p-button-outlined:enabled:active,
// .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
// .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-secondary-color);
//   border: 1px solid;
// }
// .p-button.p-button-secondary.p-button-text,
// .p-buttonset.p-button-secondary > .p-button.p-button-text,
// .p-splitbutton.p-button-secondary > .p-button.p-button-text {
//   background-color: transparent;
//   color: var(--bs-secondary-color);
//   border-color: transparent;
// }
// .p-button.p-button-secondary.p-button-text:enabled:hover,
// .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
// .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
//   background: var(--bs-tertiary-bg);
//   border-color: transparent;
//   color: var(--bs-secondary-color);
// }
// .p-button.p-button-secondary.p-button-text:enabled:active,
// .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
// .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
//   background: var(--bs-tertiary-bg);
//   border-color: transparent;
//   color: var(--bs-secondary-color);
// }

// .p-button.p-button-info,
// .p-buttonset.p-button-info > .p-button,
// .p-splitbutton.p-button-info > .p-button {
//   color: color-contrast($info);
//   background: $info;
//   border: 1px solid $info;
// }
// .p-button.p-button-info:enabled:hover,
// .p-buttonset.p-button-info > .p-button:enabled:hover,
// .p-splitbutton.p-button-info > .p-button:enabled:hover {
//   background: shade-color($info, 20%);
//   color: color-contrast(shade-color($info, 20%));
//   border-color: shade-color($info, 20%);
// }
// .p-button.p-button-info:enabled:focus,
// .p-buttonset.p-button-info > .p-button:enabled:focus,
// .p-splitbutton.p-button-info > .p-button:enabled:focus {
//   box-shadow: 0 0 0 0.2rem tint-color($info, 80%);
// }
// .p-button.p-button-info:enabled:active,
// .p-buttonset.p-button-info > .p-button:enabled:active,
// .p-splitbutton.p-button-info > .p-button:enabled:active {
//   background: shade-color($info, 20%);
//   color: color-contrast(shade-color($info, 20%));
//   border-color: shade-color($info, 20%);
// }
// .p-button.p-button-info.p-button-outlined,
// .p-buttonset.p-button-info > .p-button.p-button-outlined,
// .p-splitbutton.p-button-info > .p-button.p-button-outlined {
//   background-color: transparent;
//   color: var(--bs-info-text);
//   border: 1px solid;
// }
// .p-button.p-button-info.p-button-outlined:enabled:hover,
// .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
// .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
//   background: tint-color($info, 90%);
//   color: var(--bs-info-text);
//   border: 1px solid;
// }
// .p-button.p-button-info.p-button-outlined:enabled:active,
// .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
// .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
//   background: tint-color($info, 90%);
//   color: var(--bs-info-text);
//   border: 1px solid;
// }
// .p-button.p-button-info.p-button-text,
// .p-buttonset.p-button-info > .p-button.p-button-text,
// .p-splitbutton.p-button-info > .p-button.p-button-text {
//   background-color: transparent;
//   color: var(--bs-info-text);
//   border-color: transparent;
// }
// .p-button.p-button-info.p-button-text:enabled:hover,
// .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
// .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
//   background: tint-color($info, 90%);
//   border-color: transparent;
//   color: var(--bs-info-text);
// }
// .p-button.p-button-info.p-button-text:enabled:active,
// .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
// .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
//   background: tint-color($info, 90%);
//   border-color: transparent;
//   color: var(--bs-info-text);
// }

// .p-button.p-button-success,
// .p-buttonset.p-button-success > .p-button,
// .p-splitbutton.p-button-success > .p-button {
//   color: color-contrast($success);
//   background: $success;
//   border: 1px solid $success;
// }
// .p-button.p-button-success:enabled:hover,
// .p-buttonset.p-button-success > .p-button:enabled:hover,
// .p-splitbutton.p-button-success > .p-button:enabled:hover {
//   background: shade-color($success, 20%);
//   color: color-contrast(shade-color($success, 20%));
//   border-color: shade-color($success, 20%);
// }
// .p-button.p-button-success:enabled:focus,
// .p-buttonset.p-button-success > .p-button:enabled:focus,
// .p-splitbutton.p-button-success > .p-button:enabled:focus {
//   box-shadow: 0 0 0 0.2rem tint-color($success, 90%);
// }
// .p-button.p-button-success:enabled:active,
// .p-buttonset.p-button-success > .p-button:enabled:active,
// .p-splitbutton.p-button-success > .p-button:enabled:active {
//   background: shade-color($success, 30%);
//   color: color-contrast(shade-color($success, 30%));
//   border-color: shade-color($success, 30%);
// }
// .p-button.p-button-success.p-button-outlined,
// .p-buttonset.p-button-success > .p-button.p-button-outlined,
// .p-splitbutton.p-button-success > .p-button.p-button-outlined {
//   background-color: transparent;
//   color: var(--bs-success-text);
//   border: 1px solid;
// }
// .p-button.p-button-success.p-button-outlined:enabled:hover,
// .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
// .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
//   background: tint-color($success, 90%);
//   color: var(--bs-success-text);
//   border: 1px solid;
// }
// .p-button.p-button-success.p-button-outlined:enabled:active,
// .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
// .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
//   background: tint-color($success, 90%);
//   color: var(--bs-success-text);
//   border: 1px solid;
// }
// .p-button.p-button-success.p-button-text,
// .p-buttonset.p-button-success > .p-button.p-button-text,
// .p-splitbutton.p-button-success > .p-button.p-button-text {
//   background-color: transparent;
//   color: var(--bs-success-text);
//   border-color: transparent;
// }
// .p-button.p-button-success.p-button-text:enabled:hover,
// .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
// .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
//   background: tint-color($success, 90%);
//   border-color: transparent;
//   color: var(--bs-success-text);
// }
// .p-button.p-button-success.p-button-text:enabled:active,
// .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
// .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
//   background: tint-color($success, 90%);
//   border-color: transparent;
//   color: var(--bs-success-text);
// }

// .p-button.p-button-warning,
// .p-buttonset.p-button-warning > .p-button,
// .p-splitbutton.p-button-warning > .p-button {
//   color: color-contrast($warning);
//   background: $warning;
//   border: 1px solid $warning;
// }
// .p-button.p-button-warning:enabled:hover,
// .p-buttonset.p-button-warning > .p-button:enabled:hover,
// .p-splitbutton.p-button-warning > .p-button:enabled:hover {
//   background: shade-color($warning, 20%);
//   color: color-contrast(shade-color($warning, 20%));
//   border-color: shade-color($warning, 20%);
// }
// .p-button.p-button-warning:enabled:focus,
// .p-buttonset.p-button-warning > .p-button:enabled:focus,
// .p-splitbutton.p-button-warning > .p-button:enabled:focus {
//   box-shadow: 0 0 0 0.2rem tint-color($warning, 80%);
// }
// .p-button.p-button-warning:enabled:active,
// .p-buttonset.p-button-warning > .p-button:enabled:active,
// .p-splitbutton.p-button-warning > .p-button:enabled:active {
//   background: shade-color($warning, 50%);
//   color: color-contrast(shade-color($warning, 50%));
//   border-color: shade-color($warning, 50%);
// }
// .p-button.p-button-warning.p-button-outlined,
// .p-buttonset.p-button-warning > .p-button.p-button-outlined,
// .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
//   background-color: transparent;
//   color: var(--bs-warning-text);
//   border: 1px solid;
// }
// .p-button.p-button-warning.p-button-outlined:enabled:hover,
// .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
// .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
//   background: shade-color($warning, 50%);
//   color: var(--bs-warning-text);
//   border: 1px solid;
// }
// .p-button.p-button-warning.p-button-outlined:enabled:active,
// .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
// .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
//   background: shade-color($warning, 50%);
//   color: var(--bs-warning-text);
//   border: 1px solid;
// }
// .p-button.p-button-warning.p-button-text,
// .p-buttonset.p-button-warning > .p-button.p-button-text,
// .p-splitbutton.p-button-warning > .p-button.p-button-text {
//   background-color: transparent;
//   color: var(--bs-warning-text);
//   border-color: transparent;
// }
// .p-button.p-button-warning.p-button-text:enabled:hover,
// .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
// .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
//   background: shade-color($warning, 50%);
//   border-color: transparent;
//   color: var(--bs-warning-text);
// }
// .p-button.p-button-warning.p-button-text:enabled:active,
// .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
// .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
//   background: shade-color($warning, 50%);
//   border-color: transparent;
//   color: var(--bs-warning-text);
// }

// .p-button.p-button-help,
// .p-buttonset.p-button-help > .p-button,
// .p-splitbutton.p-button-help > .p-button {
//   color: color-contrast($help);
//   background: $help;
//   border: 1px solid $help;
// }
// .p-button.p-button-help:enabled:hover,
// .p-buttonset.p-button-help > .p-button:enabled:hover,
// .p-splitbutton.p-button-help > .p-button:enabled:hover {
//   background: shade-color($help, 20%);
//   color: color-contrast(shade-color($help, 20%));
//   border-color: shade-color($help, 20%);
// }
// .p-button.p-button-help:enabled:focus,
// .p-buttonset.p-button-help > .p-button:enabled:focus,
// .p-splitbutton.p-button-help > .p-button:enabled:focus {
//   box-shadow: 0 0 0 0.2rem tint-color($help, 90%);
// }
// .p-button.p-button-help:enabled:active,
// .p-buttonset.p-button-help > .p-button:enabled:active,
// .p-splitbutton.p-button-help > .p-button:enabled:active {
//   background: shade-color($help, 30%);
//   color: color-contrast(shade-color($help, 30%));
//   border-color: shade-color($help, 30%);
// }
// .p-button.p-button-help.p-button-outlined,
// .p-buttonset.p-button-help > .p-button.p-button-outlined,
// .p-splitbutton.p-button-help > .p-button.p-button-outlined {
//   background-color: transparent;
//   color: var(--bs-tertiary-color);
//   border: 1px solid;
// }
// .p-button.p-button-help.p-button-outlined:enabled:hover,
// .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
// .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
//   background: shade-color($help, 80%);
//   color: var(--bs-tertiary-color);
//   border: 1px solid;
// }
// .p-button.p-button-help.p-button-outlined:enabled:active,
// .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
// .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
//   background: shade-color($help, 80%);
//   color: var(--bs-tertiary-color);
//   border: 1px solid;
// }
// .p-button.p-button-help.p-button-text,
// .p-buttonset.p-button-help > .p-button.p-button-text,
// .p-splitbutton.p-button-help > .p-button.p-button-text {
//   background-color: transparent;
//   color: var(--bs-tertiary-color);
//   border-color: transparent;
// }
// .p-button.p-button-help.p-button-text:enabled:hover,
// .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
// .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
//   background: shade-color($help, 80%);
//   border-color: transparent;
//   color: var(--bs-tertiary-color);
// }
// .p-button.p-button-help.p-button-text:enabled:active,
// .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
// .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
//   background: shade-color($help, 80%);
//   border-color: transparent;
//   color: var(--bs-tertiary-color);
// }

// .p-button.p-button-danger,
// .p-buttonset.p-button-danger > .p-button,
// .p-splitbutton.p-button-danger > .p-button {
//   color: color-contrast($danger);
//   background: $danger;
//   border: 1px solid $danger;
// }
// .p-button.p-button-danger:enabled:hover,
// .p-buttonset.p-button-danger > .p-button:enabled:hover,
// .p-splitbutton.p-button-danger > .p-button:enabled:hover {
//   background: shade-color($danger, 20%);
//   color: color-contrast(shade-color($danger, 20%));
//   border-color: shade-color($danger, 20%);
// }
// .p-button.p-button-danger:enabled:focus,
// .p-buttonset.p-button-danger > .p-button:enabled:focus,
// .p-splitbutton.p-button-danger > .p-button:enabled:focus {
//   box-shadow: 0 0 0 0.2rem tint-color($danger, 90%);
// }
// .p-button.p-button-danger:enabled:active,
// .p-buttonset.p-button-danger > .p-button:enabled:active,
// .p-splitbutton.p-button-danger > .p-button:enabled:active {
//   background: shade-color($danger, 30%);
//   color: color-contrast(shade-color($danger, 30%));
//   border-color: shade-color($danger, 30%);
// }
// .p-button.p-button-danger.p-button-outlined,
// .p-buttonset.p-button-danger > .p-button.p-button-outlined,
// .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
//   background-color: transparent;
//   color: var(--bs-danger);
//   border: 1px solid;
// }
// .p-button.p-button-danger.p-button-outlined:enabled:hover,
// .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
// .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
//   background: tint-color($danger, 97%);
//   color: var(--bs-danger);
//   border: 1px solid;
// }
// .p-button.p-button-danger.p-button-outlined:enabled:active,
// .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
// .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
//   background: tint-color($danger, 97%);
//   color: var(--bs-danger);
//   border: 1px solid;
// }
// .p-button.p-button-danger.p-button-text,
// .p-buttonset.p-button-danger > .p-button.p-button-text,
// .p-splitbutton.p-button-danger > .p-button.p-button-text {
//   background-color: transparent;
//   color: var(--bs-danger);
//   border-color: transparent;
// }
// .p-button.p-button-danger.p-button-text:enabled:hover,
// .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
// .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
//   background: tint-color($danger, 97%);
//   border-color: transparent;
//   color: var(--bs-danger);
// }
// .p-button.p-button-danger.p-button-text:enabled:active,
// .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
// .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
//   background: tint-color($danger, 97%);
//   border-color: transparent;
//   color: var(--bs-danger);
// }

// .p-button.p-button-link {
//   color: var(--bs-emphasis-color);
//   background: transparent;
//   border: transparent;
// }
// .p-button.p-button-link:enabled:hover {
//   background: transparent;
//   color: var(--bs-emphasis-color);
//   border-color: transparent;
// }
// .p-button.p-button-link:enabled:hover .p-button-label {
//   text-decoration: underline;
// }
// .p-button.p-button-link:enabled:focus {
//   background: transparent;
//   box-shadow: var(--bs-btn-focus-box-shadow);
//   border-color: transparent;
// }
// .p-button.p-button-link:enabled:active {
//   background: transparent;
//   color: var(--bs-emphasis-color);
//   border-color: transparent;
// }

// .p-speeddial-button.p-button.p-button-icon-only {
//   width: 4rem;
//   height: 4rem;
// }
// .p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
//   font-size: 1.3rem;
// }

// .p-speeddial-action {
//   width: 3rem;
//   height: 3rem;
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
// }
// .p-speeddial-action:hover {
//   background: shade-color($secondary, 60%);
//   color: color-contrast(shade-color($secondary, 60%));
// }

// .p-speeddial-direction-up .p-speeddial-item {
//   margin: 0.25rem 0;
// }
// .p-speeddial-direction-up .p-speeddial-item:first-child {
//   margin-bottom: 0.5rem;
// }

// .p-speeddial-direction-down .p-speeddial-item {
//   margin: 0.25rem 0;
// }
// .p-speeddial-direction-down .p-speeddial-item:first-child {
//   margin-top: 0.5rem;
// }

// .p-speeddial-direction-left .p-speeddial-item {
//   margin: 0 0.25rem;
// }
// .p-speeddial-direction-left .p-speeddial-item:first-child {
//   margin-right: 0.5rem;
// }

// .p-speeddial-direction-right .p-speeddial-item {
//   margin: 0 0.25rem;
// }
// .p-speeddial-direction-right .p-speeddial-item:first-child {
//   margin-left: 0.5rem;
// }

// .p-speeddial-circle .p-speeddial-item,
// .p-speeddial-semi-circle .p-speeddial-item,
// .p-speeddial-quarter-circle .p-speeddial-item {
//   margin: 0;
// }
// .p-speeddial-circle .p-speeddial-item:first-child,
// .p-speeddial-circle .p-speeddial-item:last-child,
// .p-speeddial-semi-circle .p-speeddial-item:first-child,
// .p-speeddial-semi-circle .p-speeddial-item:last-child,
// .p-speeddial-quarter-circle .p-speeddial-item:first-child,
// .p-speeddial-quarter-circle .p-speeddial-item:last-child {
//   margin: 0;
// }

// .p-speeddial-mask {
//   background-color: rgba(0, 0, 0, 0.4);
// }

// .p-splitbutton {
//   border-radius: var(--bs-border-radius);
// }
// .p-splitbutton.p-button-outlined > .p-button {
//   background-color: transparent;
//   color: var(--bs-secondary-color);
//   border: 1px solid;
// }
// .p-splitbutton.p-button-outlined > .p-button:enabled:hover,
// .p-splitbutton.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($secondary, 90%);
//   color: var(--bs-secondary-color);
// }
// .p-splitbutton.p-button-outlined > .p-button:enabled:active,
// .p-splitbutton.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($secondary, 90%);
//   color: var(--bs-secondary-color);
// }
// .p-splitbutton.p-button-outlined.p-button-plain > .p-button {
//   color: var(--bs-body-color);
//   border-color: var(--bs-secondary-bg);
// }
// .p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:hover,
// .p-splitbutton.p-button-outlined.p-button-plain
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-splitbutton.p-button-outlined.p-button-plain > .p-button:enabled:active,
// .p-splitbutton.p-button-outlined.p-button-plain
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: $gray-300;
//   color: var(--bs-body-color);
// }
// .p-splitbutton.p-button-text > .p-button {
//   background-color: transparent;
//   color: var(--bs-secondary-color);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-text > .p-button:enabled:hover,
// .p-splitbutton.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($secondary, 90%);
//   color: var(--bs-secondary-color);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-text > .p-button:enabled:active,
// .p-splitbutton.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($secondary, 90%);
//   color: var(--bs-secondary-color);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-text.p-button-plain > .p-button {
//   color: var(--bs-body-color);
// }
// .p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:hover,
// .p-splitbutton.p-button-text.p-button-plain
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-splitbutton.p-button-text.p-button-plain > .p-button:enabled:active,
// .p-splitbutton.p-button-text.p-button-plain
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: $gray-300;
//   color: var(--bs-body-color);
// }
// .p-splitbutton.p-button-raised {
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//     0 1px 5px 0 rgba(0, 0, 0, 0.12);
// }
// .p-splitbutton.p-button-rounded {
//   border-radius: 2rem;
// }
// .p-splitbutton.p-button-rounded > .p-button {
//   border-radius: 2rem;
// }
// .p-splitbutton.p-button-sm > .p-button {
//   font-size: 0.875rem;
//   padding: 0.65625rem 1.09375rem;
// }
// .p-splitbutton.p-button-sm > .p-button .p-button-icon {
//   font-size: 0.875rem;
// }
// .p-splitbutton.p-button-lg > .p-button {
//   font-size: 1.25rem;
//   padding: 0.9375rem 1.5625rem;
// }
// .p-splitbutton.p-button-lg > .p-button .p-button-icon {
//   font-size: 1.25rem;
// }

// .p-splitbutton.p-button-secondary.p-button-outlined > .p-button {
//   background-color: transparent;
//   color: var(--bs-secondary-color);
//   border: 1px solid;
// }
// .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:hover,
// .p-splitbutton.p-button-secondary.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-secondary-color);
// }
// .p-splitbutton.p-button-secondary.p-button-outlined > .p-button:enabled:active,
// .p-splitbutton.p-button-secondary.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-secondary-color);
// }
// .p-splitbutton.p-button-secondary.p-button-text > .p-button {
//   background-color: transparent;
//   color: var(--bs-secondary-color);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:hover,
// .p-splitbutton.p-button-secondary.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
//   border-color: transparent;
//   color: var(--bs-secondary-color);
// }
// .p-splitbutton.p-button-secondary.p-button-text > .p-button:enabled:active,
// .p-splitbutton.p-button-secondary.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: var(--bs-tertiary-bg);
//   border-color: transparent;
//   color: var(--bs-secondary-color);
// }

// .p-splitbutton.p-button-info.p-button-outlined > .p-button {
//   background-color: transparent;
//   color: var(--bs-info-text);
//   border: 1px solid;
// }
// .p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:hover,
// .p-splitbutton.p-button-info.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($info, 90%);
//   color: var(--bs-info-text);
// }
// .p-splitbutton.p-button-info.p-button-outlined > .p-button:enabled:active,
// .p-splitbutton.p-button-info.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($info, 90%);
//   color: var(--bs-info-text);
// }
// .p-splitbutton.p-button-info.p-button-text > .p-button {
//   background-color: transparent;
//   color: var(--bs-info-text);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-info.p-button-text > .p-button:enabled:hover,
// .p-splitbutton.p-button-info.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($info, 90%);
//   border-color: transparent;
//   color: var(--bs-info-text);
// }
// .p-splitbutton.p-button-info.p-button-text > .p-button:enabled:active,
// .p-splitbutton.p-button-info.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($info, 90%);
//   border-color: transparent;
//   color: var(--bs-info-text);
// }

// .p-splitbutton.p-button-success.p-button-outlined > .p-button {
//   background-color: transparent;
//   color: var(--bs-success-text);
//   border: 1px solid;
// }
// .p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:hover,
// .p-splitbutton.p-button-success.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($success, 90%);
//   color: var(--bs-success-text);
// }
// .p-splitbutton.p-button-success.p-button-outlined > .p-button:enabled:active,
// .p-splitbutton.p-button-success.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($success, 90%);
//   color: var(--bs-success-text);
// }
// .p-splitbutton.p-button-success.p-button-text > .p-button {
//   background-color: transparent;
//   color: var(--bs-success-text);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-success.p-button-text > .p-button:enabled:hover,
// .p-splitbutton.p-button-success.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($success, 90%);
//   border-color: transparent;
//   color: var(--bs-success-text);
// }
// .p-splitbutton.p-button-success.p-button-text > .p-button:enabled:active,
// .p-splitbutton.p-button-success.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($success, 90%);
//   border-color: transparent;
//   color: var(--bs-success-text);
// }

// .p-splitbutton.p-button-warning.p-button-outlined > .p-button {
//   background-color: transparent;
//   color: var(--bs-warning-text);
//   border: 1px solid;
// }
// .p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:hover,
// .p-splitbutton.p-button-warning.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($warning, 90%);
//   color: var(--bs-warning-text);
// }
// .p-splitbutton.p-button-warning.p-button-outlined > .p-button:enabled:active,
// .p-splitbutton.p-button-warning.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($warning, 90%);
//   color: var(--bs-warning-text);
// }
// .p-splitbutton.p-button-warning.p-button-text > .p-button {
//   background-color: transparent;
//   color: var(--bs-warning-text);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:hover,
// .p-splitbutton.p-button-warning.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($warning, 90%);
//   border-color: transparent;
//   color: var(--bs-warning-text);
// }
// .p-splitbutton.p-button-warning.p-button-text > .p-button:enabled:active,
// .p-splitbutton.p-button-warning.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($warning, 90%);
//   border-color: transparent;
//   color: var(--bs-warning-text);
// }

// .p-splitbutton.p-button-help.p-button-outlined > .p-button {
//   background-color: transparent;
//   color: var(--bs-tertiary-color);
//   border: 1px solid;
// }
// .p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:hover,
// .p-splitbutton.p-button-help.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: shade-color($help, 80%);
//   color: var(--bs-tertiary-color);
// }
// .p-splitbutton.p-button-help.p-button-outlined > .p-button:enabled:active,
// .p-splitbutton.p-button-help.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: shade-color($help, 80%);
//   color: var(--bs-tertiary-color);
// }
// .p-splitbutton.p-button-help.p-button-text > .p-button {
//   background-color: transparent;
//   color: var(--bs-tertiary-color);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-help.p-button-text > .p-button:enabled:hover,
// .p-splitbutton.p-button-help.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: shade-color($help, 80%);
//   border-color: transparent;
//   color: var(--bs-tertiary-color);
// }
// .p-splitbutton.p-button-help.p-button-text > .p-button:enabled:active,
// .p-splitbutton.p-button-help.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: shade-color($help, 80%);
//   border-color: transparent;
//   color: var(--bs-tertiary-color);
// }

// .p-splitbutton.p-button-danger.p-button-outlined > .p-button {
//   background-color: transparent;
//   color: var(--bs-danger);
//   border: 1px solid;
// }
// .p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:hover,
// .p-splitbutton.p-button-danger.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($danger, 95%);
//   color: var(--bs-danger);
// }
// .p-splitbutton.p-button-danger.p-button-outlined > .p-button:enabled:active,
// .p-splitbutton.p-button-danger.p-button-outlined
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($danger, 95%);
//   color: var(--bs-danger);
// }
// .p-splitbutton.p-button-danger.p-button-text > .p-button {
//   background-color: transparent;
//   color: var(--bs-danger);
//   border-color: transparent;
// }
// .p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:hover,
// .p-splitbutton.p-button-danger.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):hover {
//   background: tint-color($danger, 95%);
//   border-color: transparent;
//   color: var(--bs-danger);
// }
// .p-splitbutton.p-button-danger.p-button-text > .p-button:enabled:active,
// .p-splitbutton.p-button-danger.p-button-text
//   > .p-button:not(button):not(a):not(.p-disabled):active {
//   background: tint-color($danger, 95%);
//   border-color: transparent;
//   color: var(--bs-danger);
// }

// .p-carousel .p-carousel-content .p-carousel-prev,
// .p-carousel .p-carousel-content .p-carousel-next {
//   width: 2rem;
//   height: 2rem;
//   color: var(--bs-body-color);
//   border: 0 none;
//   background: transparent;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
//   margin: 0.5rem;
// }
// .p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
// .p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
//   color: var(--bs-body-color);
//   border-color: transparent;
//   background: var(--bs-tertiary-bg);
// }
// .p-carousel .p-carousel-content .p-carousel-prev:focus,
// .p-carousel .p-carousel-content .p-carousel-next:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-carousel .p-carousel-indicators {
//   padding: 1rem;
// }
// .p-carousel .p-carousel-indicators .p-carousel-indicator {
//   margin-right: 0.5rem;
//   margin-bottom: 0.5rem;
// }
// .p-carousel .p-carousel-indicators .p-carousel-indicator button {
//   background-color: $gray-400;
//   width: 2rem;
//   height: 0.5rem;
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
// }
// .p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
//   background: $gray-500;
// }
// .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }

@import "./prime/datatables";

// .p-dataview .p-paginator-top {
//   border-width: 0 0 1px 0;
//   border-radius: var(--bs-border-radius);
// }
// .p-dataview .p-paginator-bottom {
//   border-width: 0 0 1px 0;
//   border-radius: var(--bs-border-radius);
// }
// .p-dataview .p-dataview-header {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 1px 0 1px 0;
//   padding: 1rem 1rem;
//   font-weight: 700;
// }
// .p-dataview .p-dataview-content {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   padding: 0;
// }
// .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
//   border: solid $gray-300;
//   border-width: 0 0 1px 0;
// }
// .p-dataview .p-dataview-footer {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 0 0 1px 0;
//   padding: 1rem 1rem;
//   font-weight: 700;
//   border-bottom-left-radius: var(--bs-border-radius);
//   border-bottom-right-radius: var(--bs-border-radius);
// }
// .p-dataview .p-dataview-loading-icon {
//   font-size: 2rem;
// }
// .p-dataview .p-dataview-emptymessage {
//   padding: 1.25rem;
// }

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: var(--bs-body-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: var(--bs-transition-base);
}
.p-column-filter-menu-button:hover {
  color: var(--bs-body-color);
  border-color: transparent;
  background: var(--bs-tertiary-bg);
}
.p-column-filter-menu-button.p-column-filter-menu-button-open,
.p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
}
.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: var(--bs-tertiary-bg);
  color: var(--bs-emphasis-color);
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: var(--bs-body-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: var(--bs-transition-base);
}
.p-column-filter-clear-button:hover {
  color: var(--bs-body-color);
  border-color: transparent;
  background: var(--bs-tertiary-bg);
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.p-column-filter-overlay {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 0 none;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.75rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.75rem 1.25rem;
  border: 0 none;
  color: var(--bs-body-color);
  background: transparent;
  transition: var(--bs-transition-base);
  border-radius: var(--bs-border-radius);
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item.p-highlight {
  color: var(--bs-emphasis-color);
  background: var(--bs-tertiary-bg);
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
}
.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid var(--bs-border-color);
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem 1.25rem;
  border: 1px solid var(--bs-border-color);
  color: var(--bs-body-color);
  background: var(--bs-tertiary-bg);
  margin: 0;
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border: 1px solid var(--bs-border-color);
}
.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu
  .p-column-filter-constraint
  .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.75rem 1.25rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}

// .fc {
//   /* FullCalendar 4 */
//   /* FullCalendar 5 */
// }
// .fc.fc-unthemed .fc-view-container th {
//   background: var(--bs-tertiary-bg);
//   border: 1px solid var(--bs-border-color-translucent);
//   color: var(--bs-body-color);
// }
// .fc.fc-unthemed .fc-view-container td.fc-widget-content {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color-translucent);
//   color: var(--bs-body-color);
// }
// .fc.fc-unthemed .fc-view-container td.fc-head-container {
//   border: 1px solid var(--bs-border-color-translucent);
// }
// .fc.fc-unthemed .fc-view-container .fc-row {
//   border-right: 1px solid $gray-300;
// }
// .fc.fc-unthemed .fc-view-container .fc-event {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   border: 1px solid shade-color($secondary, 20%);
//   color: color-contrast(shade-color($secondary, 20%));
// }
// .fc.fc-unthemed .fc-view-container .fc-divider {
//   background: var(--bs-tertiary-bg);
//   border: 1px solid var(--bs-border-color-translucent);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button {
//   color: color-contrast($secondary);
//   background: var(--bs-secondary-color);
//   border: 1px solid var(--bs-secondary-color);
//   font-size: 1rem;
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
//   display: flex;
//   align-items: center;
// }
// .fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   color: color-contrast(shade-color($secondary, 20%));
//   border-color: var(--bs-emphasis-color);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   color: color-contrast(shade-color($secondary, 20%));
//   border-color: var(--bs-emphasis-color);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
//   font-family: "PrimeIcons" !important;
//   text-indent: 0;
//   font-size: 1rem;
// }
// .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
//   content: "\e900";
// }
// .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
//   font-family: "PrimeIcons" !important;
//   text-indent: 0;
//   font-size: 1rem;
// }
// .fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
//   content: "\e901";
// }
// .fc.fc-unthemed .fc-toolbar .fc-button:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color);
//   color: var(--bs-body-color);
//   transition: var(--bs-transition-base);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
//   background: var(--bs-tertiary-bg);
//   border-color: $gray-400;
//   color: var(--bs-body-color);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
//   background: var(--bs-secondary-color);
//   border-color: var(--bs-border-color);
//   color: color-contrast($secondary);
// }
// .fc.fc-unthemed
//   .fc-toolbar
//   .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
// .fc.fc-unthemed
//   .fc-toolbar
//   .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
// .fc.fc-unthemed
//   .fc-toolbar
//   .fc-button.fc-timeGridDay-button.fc-button-active:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   border-color: var(--bs-emphasis-color);
//   color: color-contrast(shade-color($secondary, 20%));
// }
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus,
// .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
//   z-index: 1;
// }
// .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
//   border-radius: var(--bs-border-radius);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
//   border-top-left-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }
// .fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
//   border-top-right-radius: var(--bs-border-radius);
//   border-bottom-right-radius: var(--bs-border-radius);
// }
// .fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
//   background: var(--bs-tertiary-color);
// }
// .fc.fc-theme-standard .fc-view-harness th {
//   background: var(--bs-tertiary-bg);
//   background: var(--bs-tertiary-color);
//   color: var(--bs-body-color);
// }
// .fc.fc-theme-standard .fc-view-harness td {
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-color);
// }
// .fc.fc-theme-standard .fc-view-harness .fc-view {
//   background: var(--bs-body-bg);
// }
// .fc.fc-theme-standard .fc-view-harness .fc-popover {
//   background: none;
//   border: 0 none;
// }
// .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
//   border: 1px solid var(--bs-border-color-translucent);
//   padding: 1.25rem;
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .fc.fc-theme-standard
//   .fc-view-harness
//   .fc-popover
//   .fc-popover-header
//   .fc-popover-close {
//   opacity: 1;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   overflow: hidden;
//   font-family: "PrimeIcons" !important;
//   font-size: 1rem;
//   width: 2rem;
//   height: 2rem;
//   color: var(--bs-body-color);
//   border: 0 none;
//   background: transparent;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
// }
// .fc.fc-theme-standard
//   .fc-view-harness
//   .fc-popover
//   .fc-popover-header
//   .fc-popover-close:before {
//   content: "\e90b";
// }
// .fc.fc-theme-standard
//   .fc-view-harness
//   .fc-popover
//   .fc-popover-header
//   .fc-popover-close:enabled:hover {
//   color: var(--bs-body-color);
//   border-color: transparent;
//   background: var(--bs-tertiary-bg);
// }
// .fc.fc-theme-standard
//   .fc-view-harness
//   .fc-popover
//   .fc-popover-header
//   .fc-popover-close:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
//   padding: 1.25rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border-top: 0 none;
// }
// .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
//   color: color-contrast(shade-color($secondary, 20%));
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   border-color: var(--bs-emphasis-color);
// }
// .fc.fc-theme-standard
//   .fc-view-harness
//   .fc-event.fc-daygrid-block-event
//   .fc-event-main {
//   color: var(--bs-body-color);
// }
// .fc.fc-theme-standard
//   .fc-view-harness
//   .fc-event.fc-daygrid-dot-event
//   .fc-daygrid-event-dot {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   border-color: var(--bs-emphasis-color);
// }
// .fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
//   background: var(--bs-tertiary-bg);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button {
//   color: color-contrast($secondary);
//   background: var(--bs-secondary-color);
//   border: 1px solid var(--bs-secondary-color);
//   font-size: 1rem;
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   color: color-contrast(shade-color($secondary, 20%));
//   border-color: var(--bs-emphasis-color);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   color: color-contrast(shade-color($secondary, 20%));
//   border-color: var(--bs-emphasis-color);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
//   opacity: 0.6;
//   color: color-contrast($secondary);
//   background: var(--bs-secondary-color);
//   border: 1px solid var(--bs-secondary-color);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
//   font-family: "PrimeIcons" !important;
//   text-indent: 0;
//   font-size: 1rem;
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
//   content: "\e900";
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
//   font-family: "PrimeIcons" !important;
//   text-indent: 0;
//   font-size: 1rem;
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
//   content: "\e901";
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button,
// .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button,
// .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color);
//   color: var(--bs-body-color);
//   transition: var(--bs-transition-base);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover,
// .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover,
// .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
//   background: var(--bs-tertiary-bg);
//   border-color: $gray-400;
//   color: var(--bs-body-color);
// }
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-dayGridMonth-button.fc-button-active,
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-timeGridWeek-button.fc-button-active,
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-timeGridDay-button.fc-button-active {
//   background: var(--bs-secondary-color);
//   border-color: var(--bs-border-color);
//   color: color-contrast($secondary);
// }
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-dayGridMonth-button.fc-button-active:hover,
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-timeGridWeek-button.fc-button-active:hover,
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-timeGridDay-button.fc-button-active:hover {
//   background: rgba(var(--bs-emphasis-color-rgb), 0.2);
//   border-color: var(--bs-emphasis-color);
//   color: color-contrast(shade-color($secondary, 20%));
// }
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-dayGridMonth-button:not(:disabled):focus,
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-timeGridWeek-button:not(:disabled):focus,
// .fc.fc-theme-standard
//   .fc-toolbar
//   .fc-button.fc-timeGridDay-button:not(:disabled):focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
//   z-index: 1;
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
//   border-radius: var(--bs-border-radius);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
//   border-top-left-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }
// .fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
//   border-top-right-radius: var(--bs-border-radius);
//   border-bottom-right-radius: var(--bs-border-radius);
// }
// .fc.fc-theme-standard a {
//   color: var(--bs-body-color);
// }
// .fc.fc-theme-standard .fc-highlight {
//   color: var(--bs-emphasis-color);
//   background: var(--bs-tertiary-bg);
// }

// .p-orderlist .p-orderlist-controls {
//   padding: 1.25rem;
// }
// .p-orderlist .p-orderlist-controls .p-button {
//   margin-bottom: 0.5rem;
// }
// .p-orderlist .p-orderlist-header {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   padding: 1.25rem;
//   border-bottom: 0 none;
//   border-top-right-radius: var(--bs-border-radius);
//   border-top-left-radius: var(--bs-border-radius);
// }
// .p-orderlist .p-orderlist-header .p-orderlist-title {
//   font-weight: 700;
// }
// .p-orderlist .p-orderlist-filter-container {
//   padding: 1.25rem;
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-bottom: 0 none;
// }
// .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
//   padding-right: 1.75rem;
// }
// .p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
//   right: 0.75rem;
//   color: var(--bs-body-color);
// }
// .p-orderlist .p-orderlist-list {
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   padding: 0.75rem 0;
//   border-bottom-right-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }
// .p-orderlist .p-orderlist-list .p-orderlist-item {
//   padding: 0.75rem 1.25rem;
//   margin: 0;
//   border: 0 none;
//   color: var(--bs-body-color);
//   background: transparent;
//   transition: var(--bs-transition-base);
// }
// .p-orderlist .p-orderlist-list .p-orderlist-item:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
//   color: var(--bs-emphasis-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-orderlist .p-orderlist-list .p-orderlist-empty-message {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
// }
// .p-orderlist
//   .p-orderlist-list:not(.cdk-drop-list-dragging)
//   .p-orderlist-item:not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-orderlist.p-orderlist-striped
//   .p-orderlist-list
//   .p-orderlist-item:nth-child(even) {
//   background: shade-color($white, 5%);
// }
// .p-orderlist.p-orderlist-striped
//   .p-orderlist-list
//   .p-orderlist-item:nth-child(even):hover {
//   background: var(--bs-tertiary-bg);
// }

// .p-orderlist-item.cdk-drag-preview {
//   padding: 0.75rem 1.25rem;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//   border: 0 none;
//   color: var(--bs-body-color);
//   background: var(--bs-body-bg);
//   margin: 0;
// }

// .p-organizationchart
//   .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-organizationchart .p-organizationchart-node-content.p-highlight {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }
// .p-organizationchart
//   .p-organizationchart-node-content.p-highlight
//   .p-node-toggler
//   i {
//   color: var(--bs-secondary-color);
// }
// .p-organizationchart .p-organizationchart-line-down {
//   background: $gray-300;
// }
// .p-organizationchart .p-organizationchart-line-left {
//   border-right: 1px solid $gray-300;
//   background: var(--bs-tertiary-color);
// }
// .p-organizationchart .p-organizationchart-line-top {
//   border-top: 1px solid var(--bs-border-color);
//   background: var(--bs-tertiary-color);
// }
// .p-organizationchart .p-organizationchart-node-content {
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   padding: 1.25rem;
// }
// .p-organizationchart .p-organizationchart-node-content .p-node-toggler {
//   background: inherit;
//   color: inherit;
//   border-radius: 50%;
// }
// .p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }

.p-paginator {
  --bs-paginator-form-control-height: 44px;

  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: solid $gray-200;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-bottom-left-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}
.p-paginator .p-paginator-element {
  border: 1px solid var(--bs-border-color);
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  // border: 0 none;
  color: var(--bs-body-color);
  min-width: var(--bs-paginator-form-control-height);
  height: var(--bs-paginator-form-control-height);
  margin: 0.143rem;
  transition: var(--bs-transition-base);
  border-radius: 50%;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: var(--bs-secondary-bg);
  border-color: var(--bs-border-color);
  color: var(--bs-body-color);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: var(--bs-paginator-form-control-height);
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: var(--bs-body-color);
  min-width: var(--bs-paginator-form-control-height);
  height: var(--bs-paginator-form-control-height);
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 1px solid var(--bs-border-color);
  color: var(--bs-body-color);
  min-width: var(--bs-paginator-form-control-height);
  height: var(--bs-paginator-form-control-height);
  margin: 0.143rem;
  transition: var(--bs-transition-base);
  border-radius: 50%;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--bs-primary);
  border-color: var(--bs-border-color);
  color: #fff;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: var(--bs-secondary-bg);
  border-color: var(--bs-border-color);
  color: var(--bs-body-color);
}

// .p-picklist .p-picklist-buttons {
//   padding: 1.25rem;
// }
// .p-picklist .p-picklist-buttons .p-button {
//   margin-bottom: 0.5rem;
// }
// .p-picklist .p-picklist-header {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   padding: 1.25rem;
//   border-bottom: 0 none;
//   border-top-right-radius: var(--bs-border-radius);
//   border-top-left-radius: var(--bs-border-radius);
// }
// .p-picklist .p-picklist-header .p-picklist-title {
//   font-weight: 700;
// }
// .p-picklist .p-picklist-filter-container {
//   padding: 1.25rem;
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-bottom: 0 none;
// }
// .p-picklist .p-picklist-filter-container .p-picklist-filter-input {
//   padding-right: 1.75rem;
// }
// .p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
//   right: 0.75rem;
//   color: var(--bs-body-color);
// }
// .p-picklist .p-picklist-list {
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   padding: 0.75rem 0;
//   border-bottom-right-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }
// .p-picklist .p-picklist-list .p-picklist-item {
//   padding: 0.75rem 1.25rem;
//   margin: 0;
//   border: 0 none;
//   color: var(--bs-body-color);
//   background: transparent;
//   transition: var(--bs-transition-base);
// }
// .p-picklist .p-picklist-list .p-picklist-item:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-picklist .p-picklist-list .p-picklist-item.p-highlight {
//   color: var(--bs-emphasis-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-picklist .p-picklist-list .p-picklist-empty-message {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
// }
// .p-picklist
//   .p-picklist-list:not(.cdk-drop-list-dragging)
//   .p-picklist-item:not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-picklist.p-picklist-striped
//   .p-picklist-list
//   .p-picklist-item:nth-child(even) {
//   background: shade-color($white, 5%);
// }
// .p-picklist.p-picklist-striped
//   .p-picklist-list
//   .p-picklist-item:nth-child(even):hover {
//   background: var(--bs-tertiary-bg);
// }

// .p-picklist-item.cdk-drag-preview {
//   padding: 0.75rem 1.25rem;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//   border: 0 none;
//   color: var(--bs-body-color);
//   background: var(--bs-body-bg);
//   margin: 0;
// }

// .p-timeline .p-timeline-event-marker {
//   border: 2px solid var(--bs-secondary-color);
//   border-radius: 50%;
//   width: 1rem;
//   height: 1rem;
//   background-color: color-contrast($secondary);
// }
// .p-timeline .p-timeline-event-connector {
//   background-color: $gray-300;
// }
// .p-timeline.p-timeline-vertical .p-timeline-event-opposite,
// .p-timeline.p-timeline-vertical .p-timeline-event-content {
//   padding: 0 1rem;
// }
// .p-timeline.p-timeline-vertical .p-timeline-event-connector {
//   width: 2px;
// }
// .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
// .p-timeline.p-timeline-horizontal .p-timeline-event-content {
//   padding: 1rem 0;
// }
// .p-timeline.p-timeline-horizontal .p-timeline-event-connector {
//   height: 2px;
// }

// .p-tree {
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   padding: 1.25rem;
//   border-radius: var(--bs-border-radius);
// }
// .p-tree .p-tree-container .p-treenode {
//   padding: 0.143rem;
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content {
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   padding: 0.5rem;
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
//   margin-right: 0.5rem;
//   width: 2rem;
//   height: 2rem;
//   color: var(--bs-body-color);
//   border: 0 none;
//   background: transparent;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
// }
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content
//   .p-tree-toggler:enabled:hover {
//   color: var(--bs-body-color);
//   border-color: transparent;
//   background: var(--bs-tertiary-bg);
// }
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content
//   .p-tree-toggler:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
//   margin-right: 0.5rem;
//   color: var(--bs-body-color);
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
//   margin-right: 0.5rem;
// }
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content
//   .p-checkbox
//   .p-indeterminate
//   .p-checkbox-icon {
//   color: var(--bs-body-color);
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content.p-highlight
//   .p-tree-toggler,
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content.p-highlight
//   .p-treenode-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content.p-highlight
//   .p-tree-toggler:hover,
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content.p-highlight
//   .p-treenode-icon:hover {
//   color: var(--bs-emphasis-color);
// }
// .p-tree
//   .p-tree-container
//   .p-treenode
//   .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-tree .p-tree-filter-container {
//   margin-bottom: 0.5rem;
// }
// .p-tree .p-tree-filter-container .p-tree-filter {
//   width: 100%;
//   padding-right: 1.75rem;
// }
// .p-tree .p-tree-filter-container .p-tree-filter-icon {
//   right: 0.75rem;
//   color: var(--bs-body-color);
// }
// .p-tree .p-treenode-children {
//   padding: 0 0 0 1rem;
// }
// .p-tree .p-tree-loading-icon {
//   font-size: 2rem;
// }
// .p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
//   background-color: tint-color($secondary, 50%);
// }
// .p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
//   border-radius: var(--bs-border-radius);
//   border: 1px solid var(--bs-border-color-translucent);
//   background-color: $white;
//   color: var(--bs-body-color);
//   padding: 0.5rem;
//   transition: var(--bs-transition-base);
// }
// .p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
//   background-color: tint-color($secondary, 95%);
//   color: var(--bs-emphasis-color);
// }
// .p-tree.p-tree-horizontal
//   .p-treenode
//   .p-treenode-content.p-highlight
//   .p-treenode-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
//   margin-right: 0.5rem;
// }
// .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
//   margin-right: 0.5rem;
// }
// .p-tree.p-tree-horizontal
//   .p-treenode
//   .p-treenode-content
//   .p-treenode-label:not(.p-highlight):hover {
//   background-color: inherit;
//   color: inherit;
// }
// .p-tree.p-tree-horizontal
//   .p-treenode
//   .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }

// .p-treetable .p-paginator-top {
//   border-width: 0 0 1px 0;
//   border-radius: var(--bs-border-radius);
// }
// .p-treetable .p-paginator-bottom {
//   border-width: 0 0 1px 0;
//   border-radius: var(--bs-border-radius);
// }
// .p-treetable .p-treetable-header {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 1px 0 1px 0;
//   padding: 1rem 1rem;
//   font-weight: 700;
// }
// .p-treetable .p-treetable-footer {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 0 0 1px 0;
//   padding: 1rem 1rem;
//   font-weight: 700;
// }
// .p-treetable .p-treetable-thead > tr > th {
//   text-align: left;
//   padding: 1rem 1rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 0 0 1px 0;
//   font-weight: 700;
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
//   transition: var(--bs-transition-base);
// }
// .p-treetable .p-treetable-tfoot > tr > td {
//   text-align: left;
//   padding: 1rem 1rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 0 0 1px 0;
//   font-weight: 700;
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
// }
// .p-treetable .p-sortable-column {
//   outline-color: tint-color($secondary, 90%);
// }
// .p-treetable .p-sortable-column .p-sortable-column-icon {
//   color: var(--bs-body-color);
//   margin-left: 0.5rem;
// }
// .p-treetable .p-sortable-column .p-sortable-column-badge {
//   border-radius: 50%;
//   height: 1.143rem;
//   min-width: 1.143rem;
//   line-height: 1.143rem;
//   color: var(--bs-emphasis-color);
//   background: var(--bs-tertiary-bg);
//   margin-left: 0.5rem;
// }
// .p-treetable .p-sortable-column:not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-treetable
//   .p-sortable-column:not(.p-highlight):hover
//   .p-sortable-column-icon {
//   color: var(--bs-body-color);
// }
// .p-treetable .p-sortable-column.p-highlight {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }
// .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-treetable .p-treetable-tbody > tr {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   transition: var(--bs-transition-base);
// }
// .p-treetable .p-treetable-tbody > tr > td {
//   text-align: left;
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 0 0 1px 0;
//   padding: 1rem 1rem;
// }
// .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
//   width: 2rem;
//   height: 2rem;
//   color: var(--bs-body-color);
//   border: 0 none;
//   background: transparent;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
//   margin-right: 0.5rem;
// }
// .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
//   color: var(--bs-body-color);
//   border-color: transparent;
//   background: var(--bs-tertiary-bg);
// }
// .p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
//   margin-right: 0.5rem;
// }
// .p-treetable
//   .p-treetable-tbody
//   > tr
//   > td
//   p-treetablecheckbox
//   .p-checkbox
//   .p-indeterminate
//   .p-checkbox-icon {
//   color: var(--bs-body-color);
// }
// .p-treetable .p-treetable-tbody > tr:focus {
//   outline: 0.15rem solid tint-color($secondary, 90%);
//   outline-offset: -0.15rem;
// }
// .p-treetable .p-treetable-tbody > tr.p-highlight {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }
// .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
//   color: var(--bs-emphasis-color);
// }
// .p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
//   color: var(--bs-emphasis-color);
// }
// .p-treetable.p-treetable-hoverable-rows
//   .p-treetable-tbody
//   > tr:not(.p-highlight):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-treetable.p-treetable-hoverable-rows
//   .p-treetable-tbody
//   > tr:not(.p-highlight):hover
//   .p-treetable-toggler {
//   color: var(--bs-body-color);
// }
// .p-treetable .p-column-resizer-helper {
//   background: var(--bs-secondary-color);
// }
// .p-treetable .p-treetable-scrollable-header,
// .p-treetable .p-treetable-scrollable-footer {
//   background: var(--bs-tertiary-bg);
// }
// .p-treetable .p-treetable-loading-icon {
//   font-size: 2rem;
// }
// .p-treetable.p-treetable-gridlines .p-datatable-header {
//   border-width: 1px 1px 0 1px;
// }
// .p-treetable.p-treetable-gridlines .p-treetable-footer {
//   border-width: 0 1px 1px 1px;
// }
// .p-treetable.p-treetable-gridlines .p-treetable-top {
//   border-width: 0 1px 0 1px;
// }
// .p-treetable.p-treetable-gridlines .p-treetable-bottom {
//   border-width: 0 1px 1px 1px;
// }
// .p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
//   border-width: 1px;
// }
// .p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
//   border-width: 1px;
// }
// .p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
//   border-width: 1px;
// }
// .p-treetable.p-treetable-sm .p-treetable-header {
//   padding: 0.875rem 0.875rem;
// }
// .p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
//   padding: 0.5rem 0.5rem;
// }
// .p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
//   padding: 0.5rem 0.5rem;
// }
// .p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
//   padding: 0.5rem 0.5rem;
// }
// .p-treetable.p-treetable-sm .p-treetable-footer {
//   padding: 0.5rem 0.5rem;
// }
// .p-treetable.p-treetable-lg .p-treetable-header {
//   padding: 1.25rem 1.25rem;
// }
// .p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
//   padding: 1.25rem 1.25rem;
// }
// .p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
//   padding: 1.25rem 1.25rem;
// }
// .p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
//   padding: 1.25rem 1.25rem;
// }
// .p-treetable.p-treetable-lg .p-treetable-footer {
//   padding: 1.25rem 1.25rem;
// }

.p-virtualscroller .p-virtualscroller-header {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}
.p-virtualscroller .p-virtualscroller-content {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: var(--bs-tertiary-bg);
  color: var(--bs-body-color);
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
  border-bottom-left-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

// .p-accordion .p-accordion-header .p-accordion-header-link {
//   padding: 1.25rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
//   font-weight: 700;
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
// }
// .p-accordion
//   .p-accordion-header
//   .p-accordion-header-link
//   .p-accordion-toggle-icon {
//   margin-right: 0.5rem;
// }
// .p-accordion
//   .p-accordion-header:not(.p-disabled)
//   .p-accordion-header-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-accordion
//   .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
//   .p-accordion-header-link {
//   background: var(--bs-tertiary-bg);
//   background: var(--bs-tertiary-color);
//   color: var(--bs-body-color);
// }
// .p-accordion
//   .p-accordion-header:not(.p-disabled).p-highlight
//   .p-accordion-header-link {
//   background: var(--bs-tertiary-bg);
//   background: var(--bs-tertiary-color);
//   color: var(--bs-body-color);
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }
// .p-accordion
//   .p-accordion-header:not(.p-disabled).p-highlight:hover
//   .p-accordion-header-link {
//   background: var(--bs-tertiary-color);
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-accordion .p-accordion-content {
//   padding: 1.25rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border-top: 0;
//   border-top-right-radius: 0;
//   border-top-left-radius: 0;
//   border-bottom-right-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }
// .p-accordion p-accordiontab .p-accordion-tab {
//   margin-bottom: 4px;
// }

// .p-card {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
//     0 1px 3px 0 rgba(0, 0, 0, 0.12);
//   border-radius: var(--bs-border-radius);
// }
// .p-card .p-card-body {
//   padding: 1.25rem;
// }
// .p-card .p-card-title {
//   font-size: 1.5rem;
//   font-weight: 700;
//   margin-bottom: 0.5rem;
// }
// .p-card .p-card-subtitle {
//   font-weight: 400;
//   margin-bottom: 0.5rem;
//   color: var(--bs-body-color);
// }
// .p-card .p-card-content {
//   padding: 1.25rem 0;
// }
// .p-card .p-card-footer {
//   padding: 1.25rem 0 0 0;
// }

.p-divider .p-divider-content {
  background-color: var(--bs-body-bg);
}
.p-divider.p-divider-horizontal {
  margin: 1.25rem 0;
  padding: 0 1.25rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px $gray-300;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1.25rem;
  padding: 1.25rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px $gray-300;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

// .p-fieldset {
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
// }
// .p-fieldset .p-fieldset-legend {
//   padding: 1.25rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
//   font-weight: 700;
//   border-radius: var(--bs-border-radius);
// }
// .p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
//   padding: 0;
//   transition: var(--bs-transition-base);
// }
// .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
//   padding: 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
// }
// .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
//   margin-right: 0.5rem;
// }
// .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
//   background: var(--bs-tertiary-bg);
//   background: var(--bs-tertiary-color);
//   color: var(--bs-body-color);
// }
// .p-fieldset .p-fieldset-content {
//   padding: 1.25rem;
// }

// .p-panel .p-panel-header {
//   border: 1px solid var(--bs-border-color-translucent);
//   padding: 1.25rem;
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border-top-right-radius: var(--bs-border-radius);
//   border-top-left-radius: var(--bs-border-radius);
// }
// .p-panel .p-panel-header .p-panel-title {
//   font-weight: 700;
// }
// .p-panel .p-panel-header .p-panel-header-icon {
//   width: 2rem;
//   height: 2rem;
//   color: var(--bs-body-color);
//   border: 0 none;
//   background: transparent;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
// }
// .p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
//   color: var(--bs-body-color);
//   border-color: transparent;
//   background: var(--bs-tertiary-bg);
// }
// .p-panel .p-panel-header .p-panel-header-icon:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-panel.p-panel-toggleable .p-panel-header {
//   padding: 0.75rem 1.25rem;
// }
// .p-panel .p-panel-content {
//   padding: 1.25rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border-bottom-right-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
//   border-top: 0 none;
// }
// .p-panel .p-panel-footer {
//   padding: 0.75rem 1.25rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border-top: 0 none;
// }
// .p-panel .p-panel-icons-end {
//   order: 2;
//   margin-left: auto;
// }
// .p-panel .p-panel-icons-start {
//   order: 0;
//   margin-right: 0.5rem;
// }
// .p-panel .p-panel-icons-center {
//   order: 2;
//   width: 100%;
//   text-align: center;
// }

// .p-scrollpanel .p-scrollpanel-bar {
//   background: var(--bs-tertiary-bg);
//   border: 0 none;
// }

// .p-splitter {
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   border-radius: var(--bs-border-radius);
//   color: var(--bs-body-color);
// }
// .p-splitter .p-splitter-gutter {
//   transition: var(--bs-transition-base);
//   background: var(--bs-tertiary-bg);
// }
// .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
//   background: $gray-300;
// }
// .p-splitter .p-splitter-gutter-resizing {
//   background: $gray-300;
// }

.p-tabview .p-tabview-nav {
  border: 1px solid var(--bs-border-color-translucent);
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid var(--bs-border-color-translucent);
  border-width: 0 0 2px 0;
  border-color: transparent transparent var(--bs-border-color-translucent)
    transparent;
  color: var(--bs-secondary-color);
  padding: 1.25rem;
  font-weight: 700;
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
  transition: var(--bs-transition-base);
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem tint-color($secondary, 90%);
}
.p-tabview
  .p-tabview-nav
  li:not(.p-highlight):not(.p-disabled):hover
  .p-tabview-nav-link {
  border-color: var(--bs-primary);
  color: var(--bs-body-color);
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  border-color: var(--bs-primary);
  color: var(--bs-body-color);
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  color: var(--bs-secondary-color);
  width: 3rem;
  box-shadow:
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: var(--bs-border-radius);
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.2rem tint-color($secondary, 90%);
}
.p-tabview .p-tabview-panels {
  padding: 1.25rem;
  border: 0 none;
  color: var(--bs-body-color);
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

// .p-toolbar {
//   background: var(--bs-tertiary-bg);
//   border: 1px solid var(--bs-border-color-translucent);
//   padding: 1.25rem;
//   border-radius: var(--bs-border-radius);
// }
// .p-toolbar .p-toolbar-separator {
//   margin: 0 0.5rem;
// }

// .p-confirm-popup {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   border-radius: var(--bs-border-radius);
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
// }
// .p-confirm-popup .p-confirm-popup-content {
//   padding: 1.25rem;
// }
// .p-confirm-popup .p-confirm-popup-footer {
//   text-align: right;
//   padding: 0.75rem 1.25rem;
// }
// .p-confirm-popup .p-confirm-popup-footer button {
//   margin: 0 0.5rem 0 0;
//   width: auto;
// }
// .p-confirm-popup .p-confirm-popup-footer button:last-child {
//   margin: 0;
// }
// .p-confirm-popup:after {
//   border: solid transparent;
//   border-color: rgba(255, 255, 255, 0);
//   border-bottom-color: var(--bs-body-bg);
// }
// .p-confirm-popup:before {
//   border: solid transparent;
//   border-color: rgba(255, 255, 255, 0);
//   border-bottom-color: var(--bs-body-bg);
// }
// .p-confirm-popup.p-confirm-popup-flipped:after {
//   border-top-color: var(--bs-body-bg);
// }
// .p-confirm-popup.p-confirm-popup-flipped:before {
//   border-top-color: var(--bs-body-bg);
// }
// .p-confirm-popup .p-confirm-popup-icon {
//   font-size: 1.5rem;
// }
// .p-confirm-popup .p-confirm-popup-message {
//   margin-left: 1rem;
// }

.p-dialog {
  border-radius: var(--bs-border-radius);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: 0 none;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  padding: 1.5rem;
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: var(--bs-body-color);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: var(--bs-transition-base);
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: var(--bs-body-color);
  border-color: transparent;
  background: var(--bs-tertiary-bg);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-overlaypanel {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border: 0 none;
  border-radius: var(--bs-border-radius);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}
.p-overlaypanel .p-overlaypanel-close {
  background: var(--bs-secondary-color);
  color: color-contrast($secondary);
  width: 2rem;
  height: 2rem;
  transition: var(--bs-transition-base);
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: rgba(var(--bs-emphasis-color-rgb), 0.2);
  color: color-contrast(shade-color($secondary, 20%));
}
.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: var(--bs-body-bg);
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: var(--bs-body-bg);
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.1);
}

// .p-sidebar {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
// }
// .p-sidebar .p-sidebar-header {
//   padding: 1.25rem;
// }
// .p-sidebar .p-sidebar-header .p-sidebar-close,
// .p-sidebar .p-sidebar-header .p-sidebar-icon {
//   width: 2rem;
//   height: 2rem;
//   color: var(--bs-body-color);
//   border: 0 none;
//   background: transparent;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
// }
// .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
// .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
//   color: var(--bs-body-color);
//   border-color: transparent;
//   background: var(--bs-tertiary-bg);
// }
// .p-sidebar .p-sidebar-header .p-sidebar-close:focus,
// .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-sidebar .p-sidebar-header + .p-sidebar-content {
//   padding-top: 0;
// }
// .p-sidebar .p-sidebar-content {
//   padding: 1.25rem;
// }

.p-tooltip .p-tooltip-text {
  background: var(--bs-body-color);
  color: var(--bs-body-bg);
  padding: 0.75rem 0.75rem;
  box-shadow: 0 2px 12px 0 var(--bs-border-color-translucent);
  border-radius: var(--bs-border-radius);
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: var(--bs-body-color);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: var(--bs-body-color);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: var(--bs-body-color);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: var(--bs-body-color);
}

.p-fileupload .p-fileupload-buttonbar {
  background: var(--bs-tertiary-bg);
  padding: 1.25rem;
  border: 1px solid var(--bs-border-color-translucent);
  color: var(--bs-body-color);
  border-bottom: 0 none;
  border-top-right-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.p-fileupload .p-fileupload-content {
  background: var(--bs-body-bg);
  padding: 2rem 1rem;
  border: 1px solid var(--bs-border-color-translucent);
  color: var(--bs-body-color);
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: rgba(var(--bs-emphasis-color-rgb), 0.2);
  color: color-contrast(shade-color($secondary, 20%));
  border-color: var(--bs-emphasis-color);
}
.p-fileupload-choose:not(.p-disabled):active {
  background: rgba(var(--bs-emphasis-color-rgb), 0.2);
  color: color-contrast(shade-color($secondary, 20%));
  border-color: var(--bs-emphasis-color);
}

.p-breadcrumb {
  background: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color-translucent);
  border-radius: var(--bs-border-radius);
  padding: 1rem;
}
.p-breadcrumb ul li .p-menuitem-link {
  transition: var(--bs-transition-base);
  border-radius: var(--bs-border-radius);
}
.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: var(--bs-body-color);
}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: var(--bs-body-color);
}
.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: var(--bs-body-color);
}
.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: var(--bs-body-color);
}
.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: var(--bs-body-color);
}

// .p-contextmenu {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//   border-radius: var(--bs-border-radius);
//   width: 12.5rem;
// }
// .p-contextmenu .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-contextmenu .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-contextmenu .p-menuitem-link .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-contextmenu .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-contextmenu .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-contextmenu .p-submenu-list {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//   border-radius: var(--bs-border-radius);
// }
// .p-contextmenu .p-menuitem {
//   margin: 0;
// }
// .p-contextmenu .p-menuitem:last-child {
//   margin: 0;
// }
// .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
//   background: var(--bs-tertiary-bg);
// }
// .p-contextmenu
//   .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-text {
//   color: var(--bs-emphasis-color);
// }
// .p-contextmenu
//   .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-icon,
// .p-contextmenu
//   .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-submenu-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-contextmenu .p-menu-separator {
//   border-top: 1px solid var(--bs-border-color);
//   margin: 0.25rem 0;
// }
// .p-contextmenu .p-submenu-icon {
//   font-size: 0.875rem;
// }

// .p-dock .p-dock-list {
//   background: rgba(255, 255, 255, 0.1);
//   border: 1px solid rgba(255, 255, 255, 0.2);
//   padding: 0.5rem 0.5rem;
//   border-radius: 0.5rem;
// }
// .p-dock .p-dock-item {
//   padding: 0.5rem;
// }
// .p-dock .p-dock-action {
//   width: 4rem;
//   height: 4rem;
// }
// .p-dock.p-dock-top .p-dock-item-second-prev,
// .p-dock.p-dock-top .p-dock-item-second-next,
// .p-dock.p-dock-bottom .p-dock-item-second-prev,
// .p-dock.p-dock-bottom .p-dock-item-second-next {
//   margin: 0 0.9rem;
// }
// .p-dock.p-dock-top .p-dock-item-prev,
// .p-dock.p-dock-top .p-dock-item-next,
// .p-dock.p-dock-bottom .p-dock-item-prev,
// .p-dock.p-dock-bottom .p-dock-item-next {
//   margin: 0 1.3rem;
// }
// .p-dock.p-dock-top .p-dock-item-current,
// .p-dock.p-dock-bottom .p-dock-item-current {
//   margin: 0 1.5rem;
// }
// .p-dock.p-dock-left .p-dock-item-second-prev,
// .p-dock.p-dock-left .p-dock-item-second-next,
// .p-dock.p-dock-right .p-dock-item-second-prev,
// .p-dock.p-dock-right .p-dock-item-second-next {
//   margin: 0.9rem 0;
// }
// .p-dock.p-dock-left .p-dock-item-prev,
// .p-dock.p-dock-left .p-dock-item-next,
// .p-dock.p-dock-right .p-dock-item-prev,
// .p-dock.p-dock-right .p-dock-item-next {
//   margin: 1.3rem 0;
// }
// .p-dock.p-dock-left .p-dock-item-current,
// .p-dock.p-dock-right .p-dock-item-current {
//   margin: 1.5rem 0;
// }

// @media screen and (max-width: 960px) {
//   .p-dock.p-dock-top .p-dock-list-container,
//   .p-dock.p-dock-bottom .p-dock-list-container {
//     overflow-x: auto;
//     width: 100%;
//   }
//   .p-dock.p-dock-top .p-dock-list-container .p-dock-list,
//   .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
//     margin: 0 auto;
//   }
//   .p-dock.p-dock-left .p-dock-list-container,
//   .p-dock.p-dock-right .p-dock-list-container {
//     overflow-y: auto;
//     height: 100%;
//   }
//   .p-dock.p-dock-left .p-dock-list-container .p-dock-list,
//   .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
//     margin: auto 0;
//   }
//   .p-dock .p-dock-list .p-dock-item {
//     transform: none;
//     margin: 0;
//   }
// }
// .p-megamenu {
//   padding: 0.5rem;
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-radius: var(--bs-border-radius);
// }
// .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem
//   > .p-menuitem-link
//   .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem
//   > .p-menuitem-link
//   .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem
//   > .p-menuitem-link
//   .p-submenu-icon {
//   color: var(--bs-body-color);
//   margin-left: 0.5rem;
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link,
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-text,
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-text {
//   color: var(--bs-emphasis-color);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-icon,
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-submenu-icon,
// .p-megamenu
//   .p-megamenu-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-submenu-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-megamenu .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-megamenu .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-megamenu .p-menuitem-link .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-megamenu .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-megamenu .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-megamenu .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-megamenu .p-megamenu-panel {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-megamenu .p-megamenu-submenu-header {
//   margin: 0;
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   background: var(--bs-body-bg);
//   font-weight: 700;
//   border-top-right-radius: var(--bs-border-radius);
//   border-top-left-radius: var(--bs-border-radius);
// }
// .p-megamenu .p-megamenu-submenu {
//   padding: 0.25rem 0;
//   width: 12.5rem;
// }
// .p-megamenu .p-megamenu-submenu .p-menu-separator {
//   border-top: 1px solid var(--bs-border-color);
//   margin: 0.25rem 0;
// }
// .p-megamenu .p-megamenu-submenu .p-menuitem {
//   margin: 0;
// }
// .p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
//   margin: 0;
// }
// .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
//   background: var(--bs-tertiary-bg);
// }
// .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-emphasis-color);
// }
// .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
// .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-megamenu.p-megamenu-vertical {
//   width: 12.5rem;
//   padding: 0.25rem 0;
// }
// .p-megamenu.p-megamenu-vertical .p-menuitem {
//   margin: 0;
// }
// .p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
//   margin: 0;
// }

// .p-menu {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-radius: var(--bs-border-radius);
//   width: 12.5rem;
// }
// .p-menu .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-menu .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-menu .p-menuitem-link .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-menu .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-menu .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-menu .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-menu.p-menu-overlay {
//   background: var(--bs-body-bg);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-menu .p-submenu-header {
//   margin: 0;
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   background: var(--bs-body-bg);
//   font-weight: 700;
//   border-top-right-radius: 0;
//   border-top-left-radius: 0;
// }
// .p-menu .p-menu-separator {
//   border-top: 1px solid var(--bs-border-color);
//   margin: 0.25rem 0;
// }
// .p-menu .p-menuitem {
//   margin: 0;
// }
// .p-menu .p-menuitem:last-child {
//   margin: 0;
// }

// .p-menubar {
//   padding: 0.5rem;
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-radius: var(--bs-border-radius);
// }
// .p-menubar .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-menubar .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-menubar .p-menuitem-link .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-menubar .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-menubar .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-menubar .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem
//   > .p-menuitem-link
//   .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem
//   > .p-menuitem-link
//   .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem
//   > .p-menuitem-link
//   .p-submenu-icon {
//   color: var(--bs-body-color);
//   margin-left: 0.5rem;
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link,
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-text,
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-text {
//   color: var(--bs-emphasis-color);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-icon,
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-submenu-icon,
// .p-menubar
//   .p-menubar-root-list
//   > .p-menuitem.p-menuitem-active
//   > .p-menuitem-link:not(.p-disabled):hover
//   .p-submenu-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-menubar .p-submenu-list {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//   width: 12.5rem;
// }
// .p-menubar .p-submenu-list .p-menu-separator {
//   border-top: 1px solid var(--bs-border-color);
//   margin: 0.25rem 0;
// }
// .p-menubar .p-submenu-list .p-submenu-icon {
//   font-size: 0.875rem;
// }
// .p-menubar .p-submenu-list .p-menuitem {
//   margin: 0;
// }
// .p-menubar .p-submenu-list .p-menuitem:last-child {
//   margin: 0;
// }
// .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
//   background: var(--bs-tertiary-bg);
// }
// .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-emphasis-color);
// }
// .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
// .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-emphasis-color);
// }

// @media screen and (max-width: 960px) {
//   .p-menubar {
//     position: relative;
//   }
//   .p-menubar .p-menubar-button {
//     display: flex;
//     width: 2rem;
//     height: 2rem;
//     color: var(--bs-body-color);
//     border-radius: 50%;
//     transition: var(--bs-transition-base);
//   }
//   .p-menubar .p-menubar-button:hover {
//     color: var(--bs-body-color);
//     background: var(--bs-tertiary-bg);
//   }
//   .p-menubar .p-menubar-button:focus {
//     outline: 0 none;
//     outline-offset: 0;
//     box-shadow: var(--bs-btn-focus-box-shadow);
//   }
//   .p-menubar .p-menubar-root-list {
//     position: absolute;
//     display: none;
//     padding: 0.25rem 0;
//     background: var(--bs-body-bg);
//     border: 0 none;
//     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//     width: 100%;
//   }
//   .p-menubar .p-menubar-root-list .p-menu-separator {
//     border-top: 1px solid var(--bs-border-color);
//     margin: 0.25rem 0;
//   }
//   .p-menubar .p-menubar-root-list .p-submenu-icon {
//     font-size: 0.875rem;
//   }
//   .p-menubar .p-menubar-root-list > .p-menuitem {
//     width: 100%;
//     position: static;
//   }
//   .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
//     padding: 0.75rem 1.25rem;
//     color: var(--bs-body-color);
//     border-radius: var(--bs-border-radius);
//     transition: var(--bs-transition-base);
//     user-select: none;
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link
//     .p-menuitem-text {
//     color: var(--bs-body-color);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link
//     .p-menuitem-icon {
//     color: var(--bs-body-color);
//     margin-right: 0.5rem;
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link
//     .p-submenu-icon {
//     color: var(--bs-body-color);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link:not(.p-disabled):hover {
//     background: var(--bs-tertiary-bg);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link:not(.p-disabled):hover
//     .p-menuitem-text {
//     color: var(--bs-body-color);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link:not(.p-disabled):hover
//     .p-menuitem-icon {
//     color: var(--bs-body-color);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link:not(.p-disabled):hover
//     .p-submenu-icon {
//     color: var(--bs-body-color);
//   }
//   .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
//     outline: 0 none;
//     outline-offset: 0;
//     box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem
//     > .p-menuitem-link
//     > .p-submenu-icon {
//     margin-left: auto;
//     transition: var(--bs-transition-base);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     > .p-menuitem.p-menuitem-active
//     > .p-menuitem-link
//     > .p-submenu-icon {
//     transform: rotate(-180deg);
//   }
//   .p-menubar .p-menubar-root-list .p-submenu-list {
//     width: 100%;
//     position: static;
//     box-shadow: none;
//     border: 0 none;
//   }
//   .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
//     transition: var(--bs-transition-base);
//     transform: rotate(90deg);
//   }
//   .p-menubar
//     .p-menubar-root-list
//     .p-submenu-list
//     .p-menuitem-active
//     > .p-menuitem-link
//     > .p-submenu-icon {
//     transform: rotate(-90deg);
//   }
//   .p-menubar .p-menubar-root-list .p-menuitem {
//     width: 100%;
//     position: static;
//   }
//   .p-menubar .p-menubar-root-list ul li a {
//     padding-left: 2.25rem;
//   }
//   .p-menubar .p-menubar-root-list ul li ul li a {
//     padding-left: 3.75rem;
//   }
//   .p-menubar .p-menubar-root-list ul li ul li ul li a {
//     padding-left: 5.25rem;
//   }
//   .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
//     padding-left: 6.75rem;
//   }
//   .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
//     padding-left: 8.25rem;
//   }
//   .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
//     display: flex;
//     flex-direction: column;
//     top: 100%;
//     left: 0;
//     z-index: 1;
//   }
// }
// .p-panelmenu .p-panelmenu-header > a {
//   padding: 1.25rem;
//   border: 1px solid var(--bs-border-color-translucent);
//   color: var(--bs-body-color);
//   background: var(--bs-tertiary-bg);
//   font-weight: 700;
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
// }
// .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
//   margin-right: 0.5rem;
// }
// .p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
//   margin-right: 0.5rem;
// }
// .p-panelmenu .p-panelmenu-header > a:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
//   background: var(--bs-tertiary-bg);
//   background: var(--bs-tertiary-color);
//   color: var(--bs-body-color);
// }
// .p-panelmenu .p-panelmenu-header.p-highlight {
//   margin-bottom: 0;
// }
// .p-panelmenu .p-panelmenu-header.p-highlight > a {
//   background: var(--bs-tertiary-bg);
//   background: var(--bs-tertiary-color);
//   color: var(--bs-body-color);
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }
// .p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
//   background: var(--bs-tertiary-color);
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-panelmenu .p-panelmenu-content {
//   padding: 0.25rem 0;
//   border: 1px solid var(--bs-border-color-translucent);
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   margin-bottom: 4px;
//   border-top: 0;
//   border-top-right-radius: 0;
//   border-top-left-radius: 0;
//   border-bottom-right-radius: var(--bs-border-radius);
//   border-bottom-left-radius: var(--bs-border-radius);
// }
// .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-menuitem
//   .p-menuitem-link
//   .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-menuitem
//   .p-menuitem-link
//   .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-menuitem
//   .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-menuitem
//   .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-menuitem
//   .p-menuitem-link:not(.p-disabled):hover
//   .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-menuitem
//   .p-menuitem-link:not(.p-disabled):hover
//   .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-menuitem
//   .p-menuitem-link
//   .p-panelmenu-icon {
//   margin-right: 0.5rem;
// }
// .p-panelmenu
//   .p-panelmenu-content
//   .p-submenu-list:not(.p-panelmenu-root-submenu) {
//   padding: 0 0 0 1rem;
// }
// .p-panelmenu .p-panelmenu-panel {
//   margin-bottom: 4px;
// }

// .p-slidemenu {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-radius: var(--bs-border-radius);
//   width: 12.5rem;
// }
// .p-slidemenu .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-slidemenu .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-slidemenu .p-menuitem-link .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-slidemenu .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-slidemenu .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-slidemenu.p-slidemenu-overlay {
//   background: var(--bs-body-bg);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-slidemenu .p-slidemenu-list {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
//   background: var(--bs-tertiary-bg);
// }
// .p-slidemenu
//   .p-slidemenu.p-slidemenu-active
//   > .p-slidemenu-link
//   .p-slidemenu-text {
//   color: var(--bs-emphasis-color);
// }
// .p-slidemenu
//   .p-slidemenu.p-slidemenu-active
//   > .p-slidemenu-link
//   .p-slidemenu-icon,
// .p-slidemenu
//   .p-slidemenu.p-slidemenu-active
//   > .p-slidemenu-link
//   .p-slidemenu-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-slidemenu .p-slidemenu-separator {
//   border-top: 1px solid var(--bs-border-color);
//   margin: 0.25rem 0;
// }
// .p-slidemenu .p-slidemenu-icon {
//   font-size: 0.875rem;
// }
// .p-slidemenu .p-slidemenu-backward {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
// }

// .p-steps .p-steps-item .p-menuitem-link {
//   background: transparent;
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
//   background: var(--bs-body-bg);
// }
// .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
//   color: var(--bs-body-color);
//   border: 1px solid $gray-200;
//   background: var(--bs-body-bg);
//   min-width: 2rem;
//   height: 2rem;
//   line-height: 2rem;
//   font-size: 1.143rem;
//   z-index: 1;
//   border-radius: 50%;
// }
// .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
//   margin-top: 0.5rem;
//   color: var(--bs-body-color);
// }
// .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-steps .p-steps-item.p-highlight .p-steps-number {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }
// .p-steps .p-steps-item.p-highlight .p-steps-title {
//   font-weight: 700;
//   color: var(--bs-body-color);
// }
// .p-steps .p-steps-item:before {
//   content: " ";
//   border-top: 1px solid var(--bs-border-color);
//   width: 100%;
//   top: 50%;
//   left: 0;
//   display: block;
//   position: absolute;
//   margin-top: -1rem;
// }

// .p-tabmenu .p-tabmenu-nav {
//   background: var(--bs-body-bg);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-width: 0 0 2px 0;
// }
// .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
//   margin-right: 0;
// }
// .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
//   border: solid $gray-300;
//   border-width: 0 0 2px 0;
//   border-color: transparent transparent $gray-300 transparent;
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   padding: 1.25rem;
//   font-weight: 700;
//   border-top-right-radius: var(--bs-border-radius);
//   border-top-left-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   margin: 0 0 -2px 0;
// }
// .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
//   margin-right: 0.5rem;
// }
// .p-tabmenu
//   .p-tabmenu-nav
//   .p-tabmenuitem
//   .p-menuitem-link:not(.p-disabled):focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.2rem tint-color($secondary, 90%);
// }
// .p-tabmenu
//   .p-tabmenu-nav
//   .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover
//   .p-menuitem-link {
//   background: var(--bs-body-bg);
//   border-color: $gray-500;
//   color: var(--bs-body-color);
// }
// .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
//   background: var(--bs-body-bg);
//   border-color: var(--bs-border-color);
//   color: var(--bs-secondary-color);
// }
// .p-tabmenu .p-tabmenu-left-icon {
//   margin-right: 0.5rem;
// }
// .p-tabmenu .p-tabmenu-right-icon {
//   margin-left: 0.5rem;
// }
// .p-tabmenu .p-tabmenu-nav-btn.p-link {
//   background: var(--bs-body-bg);
//   color: var(--bs-secondary-color);
//   width: 3rem;
//   box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
//     0 1px 5px 0 rgba(0, 0, 0, 0.12);
//   border-radius: var(--bs-border-radius);
// }
// .p-tabmenu .p-tabmenu-nav-btn.p-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.2rem tint-color($secondary, 90%);
// }

// .p-tieredmenu {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   border-radius: var(--bs-border-radius);
//   width: 12.5rem;
// }
// .p-tieredmenu .p-menuitem-link {
//   padding: 0.75rem 1.25rem;
//   color: var(--bs-body-color);
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
//   user-select: none;
// }
// .p-tieredmenu .p-menuitem-link .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-tieredmenu .p-menuitem-link .p-menuitem-icon {
//   color: var(--bs-body-color);
//   margin-right: 0.5rem;
// }
// .p-tieredmenu .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
// }
// .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
//   color: var(--bs-body-color);
// }
// .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
//   color: var(--bs-body-color);
// }
// .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
//   color: var(--bs-body-color);
// }
// .p-tieredmenu .p-menuitem-link:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: inset 0 0 0 0.15rem tint-color($secondary, 90%);
// }
// .p-tieredmenu.p-tieredmenu-overlay {
//   background: var(--bs-body-bg);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-tieredmenu .p-submenu-list {
//   padding: 0.25rem 0;
//   background: var(--bs-body-bg);
//   border: 0 none;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
// }
// .p-tieredmenu .p-menuitem {
//   margin: 0;
// }
// .p-tieredmenu .p-menuitem:last-child {
//   margin: 0;
// }
// .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
//   background: var(--bs-tertiary-bg);
// }
// .p-tieredmenu
//   .p-menuitem.p-menuitem-active
//   > .p-menuitem-link
//   .p-menuitem-text {
//   color: var(--bs-emphasis-color);
// }
// .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
// .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
//   color: var(--bs-emphasis-color);
// }
// .p-tieredmenu .p-menu-separator {
//   border-top: 1px solid var(--bs-border-color);
//   margin: 0.25rem 0;
// }
// .p-tieredmenu .p-submenu-icon {
//   font-size: 0.875rem;
// }

// .p-inline-message {
//   padding: 0.75rem 0.75rem;
//   margin: 0;
//   border-radius: var(--bs-border-radius);
// }
// .p-inline-message.p-inline-message-info {
//   background: tint-color($info, 90%);
//   border: solid $info;
//   border-width: 0px;
//   color: var(--bs-info-text);
// }
// .p-inline-message.p-inline-message-info .p-inline-message-icon {
//   color: var(--bs-info-text);
// }
// .p-inline-message.p-inline-message-success {
//   background: tint-color($success, 90%);
//   border: solid $success;
//   border-width: 0px;
//   color: var(--bs-success-text);
// }
// .p-inline-message.p-inline-message-success .p-inline-message-icon {
//   color: var(--bs-success-text);
// }
// .p-inline-message.p-inline-message-warn {
//   background: var(--bs-body-bg);
//   border: solid $warning;
//   border-width: 0px;
//   color: var(--bs-warning-text);
// }
// .p-inline-message.p-inline-message-warn .p-inline-message-icon {
//   color: var(--bs-warning-text);
// }
// .p-inline-message.p-inline-message-error {
//   background: tint-color($danger, 90%);
//   border: solid $danger;
//   border-width: 0px;
//   color: var(--bs-danger);
// }
// .p-inline-message.p-inline-message-error .p-inline-message-icon {
//   color: var(--bs-danger);
// }
// .p-inline-message .p-inline-message-icon {
//   font-size: 1rem;
//   margin-right: 0.5rem;
// }
// .p-inline-message .p-inline-message-text {
//   font-size: 1rem;
// }
// .p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
//   margin-right: 0;
// }

// .p-message {
//   margin: 1rem 0;
//   border-radius: var(--bs-border-radius);
// }
// .p-message .p-message-wrapper {
//   padding: 1.25rem 1.75rem;
// }
// .p-message .p-message-close {
//   width: 2rem;
//   height: 2rem;
//   border-radius: 50%;
//   background: transparent;
//   transition: var(--bs-transition-base);
// }
// .p-message .p-message-close:hover {
//   background: rgba(255, 255, 255, 0.3);
// }
// .p-message .p-message-close:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-message.p-message-info {
//   background: tint-color($info, 90%);
//   border: solid $info;
//   border-width: 0 0 0 6px;
//   color: var(--bs-info-text);
// }
// .p-message.p-message-info .p-message-icon {
//   color: var(--bs-info-text);
// }
// .p-message.p-message-info .p-message-close {
//   color: var(--bs-info-text);
// }
// .p-message.p-message-success {
//   background: tint-color($success, 90%);
//   border: solid $success;
//   border-width: 0 0 0 6px;
//   color: var(--bs-success-text);
// }
// .p-message.p-message-success .p-message-icon {
//   color: var(--bs-success-text);
// }
// .p-message.p-message-success .p-message-close {
//   color: var(--bs-success-text);
// }
// .p-message.p-message-warn {
//   background: var(--bs-body-bg);
//   border: solid $warning;
//   border-width: 0 0 0 6px;
//   color: var(--bs-warning-text);
// }
// .p-message.p-message-warn .p-message-icon {
//   color: var(--bs-warning-text);
// }
// .p-message.p-message-warn .p-message-close {
//   color: var(--bs-warning-text);
// }
// .p-message.p-message-error {
//   background: tint-color($danger, 90%);
//   border: solid $danger;
//   border-width: 0 0 0 6px;
//   color: var(--bs-danger);
// }
// .p-message.p-message-error .p-message-icon {
//   color: var(--bs-danger);
// }
// .p-message.p-message-error .p-message-close {
//   color: var(--bs-danger);
// }
// .p-message .p-message-text {
//   font-size: 1rem;
//   font-weight: 400;
// }
// .p-message .p-message-icon {
//   font-size: 1.5rem;
//   margin-right: 0.5rem;
// }
// .p-message .p-message-summary {
//   font-weight: 700;
// }
// .p-message .p-message-detail {
//   margin-left: 0.5rem;
// }

// .p-toast {
//   opacity: 0.9;
// }
// .p-toast .p-toast-message {
//   margin: 0 0 1rem 0;
//   box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//   border-radius: var(--bs-border-radius);
// }
// .p-toast .p-toast-message .p-toast-message-content {
//   padding: 1rem;
//   border-width: 0 0 0 6px;
// }
// .p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
//   margin: 0 0 0 1rem;
// }
// .p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
//   font-size: 2rem;
// }
// .p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
//   font-weight: 700;
// }
// .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
//   margin: 0.5rem 0 0 0;
// }
// .p-toast .p-toast-message .p-toast-icon-close {
//   width: 2rem;
//   height: 2rem;
//   border-radius: 50%;
//   background: transparent;
//   transition: var(--bs-transition-base);
// }
// .p-toast .p-toast-message .p-toast-icon-close:hover {
//   background: rgba(255, 255, 255, 0.3);
// }
// .p-toast .p-toast-message .p-toast-icon-close:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }
// .p-toast .p-toast-message.p-toast-message-info {
//   background: tint-color($info, 90%);
//   border: solid $info;
//   border-width: 0 0 0 6px;
//   color: var(--bs-info-text);
// }
// .p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
// .p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
//   color: var(--bs-info-text);
// }
// .p-toast .p-toast-message.p-toast-message-success {
//   background: tint-color($success, 90%);
//   border: solid $success;
//   border-width: 0 0 0 6px;
//   color: var(--bs-success-text);
// }
// .p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
// .p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
//   color: var(--bs-success-text);
// }
// .p-toast .p-toast-message.p-toast-message-warn {
//   background: var(--bs-body-bg);
//   border: solid $warning;
//   border-width: 0 0 0 6px;
//   color: var(--bs-warning-text);
// }
// .p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
// .p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
//   color: var(--bs-warning-text);
// }
// .p-toast .p-toast-message.p-toast-message-error {
//   background: tint-color($danger, 90%);
//   border: solid $danger;
//   border-width: 0 0 0 6px;
//   color: var(--bs-danger);
// }
// .p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
// .p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
//   color: var(--bs-danger);
// }

// .p-galleria .p-galleria-close {
//   margin: 0.5rem;
//   background: transparent;
//   color: var(--bs-tertiary-color);
//   width: 4rem;
//   height: 4rem;
//   transition: var(--bs-transition-base);
//   border-radius: 50%;
// }
// .p-galleria .p-galleria-close .p-galleria-close-icon {
//   font-size: 2rem;
// }
// .p-galleria .p-galleria-close:hover {
//   background: rgba(255, 255, 255, 0.1);
//   color: var(--bs-tertiary-color);
// }
// .p-galleria .p-galleria-item-nav {
//   background: transparent;
//   color: var(--bs-tertiary-color);
//   width: 4rem;
//   height: 4rem;
//   transition: var(--bs-transition-base);
//   border-radius: var(--bs-border-radius);
//   margin: 0 0.5rem;
// }
// .p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
// .p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
//   font-size: 2rem;
// }
// .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
//   background: rgba(255, 255, 255, 0.1);
//   color: var(--bs-tertiary-color);
// }
// .p-galleria .p-galleria-caption {
//   background: rgba(0, 0, 0, 0.5);
//   color: var(--bs-tertiary-color);
//   padding: 1rem;
// }
// .p-galleria .p-galleria-indicators {
//   padding: 1rem;
// }
// .p-galleria .p-galleria-indicators .p-galleria-indicator button {
//   background-color: $gray-400;
//   width: 1rem;
//   height: 1rem;
//   transition: var(--bs-transition-base);
//   border-radius: 50%;
// }
// .p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
//   background: $gray-500;
// }
// .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }
// .p-galleria.p-galleria-indicators-bottom .p-galleria-indicator,
// .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
//   margin-right: 0.5rem;
// }
// .p-galleria.p-galleria-indicators-left .p-galleria-indicator,
// .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
//   margin-bottom: 0.5rem;
// }
// .p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
//   background: rgba(0, 0, 0, 0.5);
// }
// .p-galleria.p-galleria-indicator-onitem
//   .p-galleria-indicators
//   .p-galleria-indicator
//   button {
//   background: rgba(255, 255, 255, 0.4);
// }
// .p-galleria.p-galleria-indicator-onitem
//   .p-galleria-indicators
//   .p-galleria-indicator
//   button:hover {
//   background: rgba(255, 255, 255, 0.6);
// }
// .p-galleria.p-galleria-indicator-onitem
//   .p-galleria-indicators
//   .p-galleria-indicator.p-highlight
//   button {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-emphasis-color);
// }
// .p-galleria .p-galleria-thumbnail-container {
//   background: rgba(0, 0, 0, 0.9);
//   padding: 1rem 0.25rem;
// }
// .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
// .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
//   margin: 0.5rem;
//   background-color: transparent;
//   color: var(--bs-tertiary-color);
//   width: 2rem;
//   height: 2rem;
//   transition: var(--bs-transition-base);
//   border-radius: 50%;
// }
// .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
// .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
//   background: rgba(255, 255, 255, 0.1);
//   color: var(--bs-tertiary-color);
// }
// .p-galleria
//   .p-galleria-thumbnail-container
//   .p-galleria-thumbnail-item-content:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }

// .p-galleria-mask {
//   --maskbg: rgba(0, 0, 0, 0.9);
// }

// .p-image-mask {
//   --maskbg: rgba(0, 0, 0, 0.9);
// }

// .p-image-preview-indicator {
//   background-color: transparent;
//   color: var(--bs-tertiary-color);
//   transition: var(--bs-transition-base);
// }

// .p-image-preview-container:hover > .p-image-preview-indicator {
//   background-color: rgba(0, 0, 0, 0.5);
// }

// .p-image-toolbar {
//   padding: 1rem;
// }

// .p-image-action.p-link {
//   color: var(--bs-tertiary-color);
//   background-color: transparent;
//   width: 3rem;
//   height: 3rem;
//   border-radius: 50%;
//   transition: var(--bs-transition-base);
//   margin-right: 0.5rem;
// }
// .p-image-action.p-link:last-child {
//   margin-right: 0;
// }
// .p-image-action.p-link:hover {
//   color: var(--bs-tertiary-color);
//   background-color: rgba(255, 255, 255, 0.1);
// }
// .p-image-action.p-link i {
//   font-size: 1.5rem;
// }

// .p-avatar {
//   background-color: $gray-300;
//   border-radius: var(--bs-border-radius);
// }
// .p-avatar.p-avatar-lg {
//   width: 3rem;
//   height: 3rem;
//   font-size: 1.5rem;
// }
// .p-avatar.p-avatar-lg .p-avatar-icon {
//   font-size: 1.5rem;
// }
// .p-avatar.p-avatar-xl {
//   width: 4rem;
//   height: 4rem;
//   font-size: 2rem;
// }
// .p-avatar.p-avatar-xl .p-avatar-icon {
//   font-size: 2rem;
// }

// .p-avatar-group .p-avatar {
//   border: 2px solid $white;
// }

.p-badge {
  background: var(--bs-primary);
  color: white;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
// .p-badge.p-badge-secondary {
//   background-color: var(--bs-secondary);
//   color: color-contrast($secondary);
// }
// .p-badge.p-badge-success {
//   background-color: var(--bs-success);
//   color: color-contrast($success);
// }
// .p-badge.p-badge-info {
//   background-color: var(--bs-info);
//   color: color-contrast($info);
// }
// .p-badge.p-badge-warning {
//   background-color: var(--bs-warning);
//   color: color-contrast($warning);
// }
// .p-badge.p-badge-danger {
//   background-color: var(--bs-danger);
//   color: color-contrast($danger);
// }
// .p-badge.p-badge-lg {
//   font-size: 1.125rem;
//   min-width: 2.25rem;
//   height: 2.25rem;
//   line-height: 2.25rem;
// }
// .p-badge.p-badge-xl {
//   font-size: 1.5rem;
//   min-width: 3rem;
//   height: 3rem;
//   line-height: 3rem;
// }

.p-chip {
  background-color: var(--bs-secondary-bg);
  color: var(--bs-emphasis-color);
  border-radius: 16px;
  padding: 0 0.75rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-top: 0.25rem;
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2.25rem;
  height: 2.25rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: var(--bs-border-radius);
  transition: var(--bs-transition-base);
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

// .p-inplace .p-inplace-display {
//   padding: 0.75rem 0.75rem;
//   border-radius: var(--bs-border-radius);
//   transition: var(--bs-transition-base);
// }
// .p-inplace .p-inplace-display:not(.p-disabled):hover {
//   background: var(--bs-tertiary-bg);
//   color: var(--bs-body-color);
// }
// .p-inplace .p-inplace-display:focus {
//   outline: 0 none;
//   outline-offset: 0;
//   box-shadow: var(--bs-btn-focus-box-shadow);
// }

// .p-progressbar {
//   border: 0 none;
//   height: 1.5rem;
//   background: $gray-300;
//   border-radius: var(--bs-border-radius);
// }
// .p-progressbar .p-progressbar-value {
//   border: 0 none;
//   margin: 0;
//   background: var(--bs-secondary-color);
// }
// .p-progressbar .p-progressbar-label {
//   color: color-contrast($secondary);
//   line-height: 1.5rem;
// }

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  transition: var(--bs-transition-base);
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: var(--bs-tertiary-color);
}

.p-skeleton {
  background-color: var(--bs-tertiary-color);
  border-radius: var(--bs-border-radius);
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}

// .p-tag {
//   background: var(--bs-secondary-color);
//   color: color-contrast($secondary);
//   font-size: 0.75rem;
//   font-weight: 700;
//   padding: 0.25rem 0.4rem;
//   border-radius: var(--bs-border-radius);
// }
// .p-tag.p-tag-success {
//   background-color: $success;
//   color: color-contrast($success);
// }
// .p-tag.p-tag-info {
//   background-color: $info;
//   color: color-contrast($info);
// }
// .p-tag.p-tag-warning {
//   background-color: $warning;
//   color: color-contrast($warning);
// }
// .p-tag.p-tag-danger {
//   background-color: var(--bs-danger);
//   color: color-contrast($danger);
// }
// .p-tag .p-tag-icon {
//   margin-right: 0.25rem;
//   font-size: 0.75rem;
// }

// .p-terminal {
//   background: var(--bs-body-bg);
//   color: var(--bs-body-color);
//   border: 1px solid var(--bs-border-color-translucent);
//   padding: 1.25rem;
// }
// .p-terminal .p-terminal-input {
//   font-size: 1rem;
// }

.p-button-label {
  font-weight: 700;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  transition: var(--bs-transition-base);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  transition: var(--bs-transition-base);
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  z-index: 1;
  display: block;
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--bs-primary);
  transition: 500ms cubic-bezier(0.35, 0, 0.25, 1);
}

// .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
//   background-color: var(--bs-secondary-color);
// }

// .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
//   background-color: var(--bs-secondary-color);
// }

// .p-button:focus {
//   box-shadow: 0 0 0 2px $white, 0 0 0 4px tint-color($secondary, 80%),
//     0 1px 2px 0 black;
// }
// .p-button.p-button-secondary:enabled:focus {
//   box-shadow: 0 0 0 2px $white, 0 0 0 4px tint-color($secondary, 80%),
//     0 1px 2px 0 black;
// }
// .p-button.p-button-success:enabled:focus {
//   box-shadow: 0 0 0 2px $white, 0 0 0 4px tint-color($success, 60%),
//     0 1px 2px 0 black;
// }
// .p-button.p-button-info:enabled:focus {
//   box-shadow: 0 0 0 2px $white, 0 0 0 4px tint-color($info, 60%),
//     0 1px 2px 0 black;
// }
// .p-button.p-button-warning:enabled:focus {
//   box-shadow: 0 0 0 2px $white, 0 0 0 4px tint-color($warning, 60%),
//     0 1px 2px 0 black;
// }
// .p-button.p-button-help:enabled:focus {
//   box-shadow: 0 0 0 2px $white, 0 0 0 4px tint-color($help, 60%),
//     0 1px 2px 0 black;
// }
// .p-button.p-button-danger:enabled:focus {
//   box-shadow: 0 0 0 2px $white, 0 0 0 4px tint-color($danger, 60%),
//     0 1px 2px 0 black;
// }

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 var(--bs-secondary-color);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 var(--bs-secondary-color);
}
