@media (max-width: 575px) {
  .container {
    max-width: calc(100vw - var(--bs-gutter-x) * 0.5);
  }
}

@media (min-width: 992px) {
  .scroll-container {
    width: 100vw;
  }
}