a {
  cursor: pointer;
}

.btn.btn-link {
  --bs-btn-border-radius: 0;

  border-width: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-align: inherit;
  vertical-align: inherit;
  padding: 0;
  user-select: initial;
}

.form-control:enabled:hover {
  border-color: var(--bs-primary);
}

@import "./_bootstrap-overrides/nav";
@import "./_bootstrap-overrides/theming";
@import "./_bootstrap-overrides/container";

:root {
  --bs-h-header: 55px;
  --bs-h-footer-with-padding: 81px;
  --bs-w-sidenav: 295px;
  --bs-h-form-control: 39px;
  --bs-h-btn: 39px;
  --bs-big-border-top-color: var(--bs-secondary);

  --bs-unread-table-row-bg: #{$unread-table-row-bg};

  --bs-transition-base: #{$transition-base};
  --bs-font-size-root: #{$font-size-root};
  --bs-input-focus-box-shadow: #{$input-focus-box-shadow};
  --bs-btn-focus-box-shadow: #{$btn-focus-box-shadow};
  --bs-input-btn-focus-box-shadow: #{$input-btn-focus-box-shadow};
  --bs-form-select-focus-box-shadow: #{$form-select-focus-box-shadow};
  --bs-form-check-input-focus-box-shadow: #{$form-check-input-focus-box-shadow};

  // Set label values, used by field wrappers' labels
  --bs-form-label-margin-bottom: #{$form-label-margin-bottom};
  --bs-form-label-font-size: #{$form-label-font-size};
  --bs-form-label-font-style: #{$form-label-font-style};
  --bs-form-label-font-weight: #{$form-label-font-weight};
  --bs-form-label-color: #{$form-label-color};

  --bs-input-font-size: #{$input-font-size};

  // Customize form fill colors
  --bs-form-radio-circle-fill-color: #{$form-radio-circle-fill-color};
  --bs-form-radio-circle-fill-color-hover: #{$form-radio-circle-fill-color-hover};
  --bs-form-checkbox-fill-color: #{$form-checkbox-fill-color};
  --bs-form-checkbox-fill-color-hover: #{$form-checkbox-fill-color-hover};
  --bs-form-toggle-fill-color: #{$form-toggle-fill-color};
  --bs-form-toggle-fill-color-hover: #{$form-toggle-fill-color-hover};
}

.h-header {
  height: var(--bs-h-header);
}

.w-sidenav {
  width: var(--bs-w-sidenav);
}

.min-vh-100 {
  // Use dynamic view height
  min-height: 100dvh !important;
}

.vh-100 {
  // Use dynamic view height
  height: 100dvh !important;
}

.flex-scroll-y {
  min-height: 0;
  flex: 1 1 0;

  overflow-y: auto;
}

@for $i from 1 through 4 {
  .line-clamp-#{$i} {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $i;
  }
  span.line-clamp-#{$i} {
    display: -webkit-inline-box;
    vertical-align: middle;
  }
}

// bootstrap has .btn-group but doesn't play nicely with nested standard-buttons, so we adjust
// Make the div behave like a button
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .btn alignment given font-size hack above

  > standard-button {
    > .btn {
      position: relative;
      flex: 1 1 auto;
    }

    // Bring the hover, focused, and "active" buttons to the front to overlay
    // the borders properly
    > .btn-check:checked + .btn,
    > .btn-check:focus + .btn,
    > .btn:hover,
    > .btn:focus,
    > .btn:active,
    > .btn.active {
      z-index: 1;
    }
  }
}

.btn-group {
  > .btn-group:not(:first-child) > standard-button > .btn,
  > standard-button:nth-child(n + 3) > .btn,
  > :not(.btn-check) + standard-button > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .btn-group:not(:last-child) > standard-button > .btn,
  > standard-button:first-child > .btn.dropdown-toggle-split,
  > standard-button:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

// When stretching cards, stretch the inner divs too
.justify-content-stretch > * {
  flex-grow: 1;

  & > standard-datatable,
  & > form,
  & > standard-page-section,
  & > standard-page-subsection,
  & > standard-page-collapser-section > standard-page-section {
    display: block;
    height: 100%;

    & > standard-page-section {
      display: block;
      height: 100%;

      & > standard-card {
        display: block;
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }

    & > standard-card {
      display: block;
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }

  & > standard-card,
  & > a > standard-card {
    display: block;
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

.align-items-stretch > * {
  & > standard-datatable,
  & > form,
  & > standard-page-section,
  & > standard-page-subsection,
  & > standard-page-collapser-section > standard-page-section,
  & > * > standard-page-collapser-section > standard-page-section {
    display: block;
    height: 100%;

    & > standard-page-section {
      display: block;
      height: 100%;

      & > standard-card {
        display: block;
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }

    & > standard-card {
      display: block;
      height: 100%;

      & > div {
        height: 100%;
      }
    }
  }

  & > standard-card,
  & > a > standard-card {
    display: block;
    height: 100%;

    & > div {
      height: 100%;
    }
  }
}

// Adjustments to list-group items to make them look good when flush inside a card
.list-group-flush {
  --bs-list-group-border-width: 0;
}

.list-group {
  > standard-button {
    > a,
    > button {
      > .btn-nested-span {
        width: 100%;
      }
    }
  }
}

standard-card .list-group-flush {
  margin-left: -1rem;
  margin-right: -1rem;
}

.list-group-flush {
  .list-group-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  standard-button + standard-button {
    > .list-group-item {
      border-top: 1px solid var(--bs-border-color);
    }
  }

  .list-group-item + .list-group-item {
    border-top: 1px solid var(--bs-border-color);
  }
}

// Ensure alerts have the appropriate colors
.alert {
  &.border {
    border: var(--bs-alert-border) !important;
  }
  &.text-body,
  .text-body {
    color: var(--bs-alert-color) !important;
  }
  &.bg-body,
  .bg-body {
    background-color: var(--bs-alert-bg) !important;
  }
}

.label {
  display: inline-block;

  margin-bottom: var(--bs-form-label-margin-bottom);
  font-size: var(--bs-form-label-font-size);
  font-style: var(--bs-form-label-font-style);
  font-weight: var(--bs-form-label-font-weight);
  color: var(--bs-form-label-color);
}

.label-inline {
  display: inline-block;

  font-size: var(--bs-form-label-font-size);
  font-style: var(--bs-form-label-font-style);
  font-weight: var(--bs-form-label-font-weight);
  color: var(--bs-form-label-color);
}

/* Extra Utility Class*/
@media screen and (max-width: 575px){
  .d-xs-none {
    display: none;
  }
}